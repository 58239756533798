import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from '../services/auth.service';
import { User } from '../models/user';
import { environment } from '../../environments/environment';
import { FormControl, Validators, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuestionWarningComponent } from '../question-warning/question-warning.component';
import { MatDialog } from '@angular/material/dialog';

declare var FB: any;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  user: User;
  userFound: User;
  hasFacebookSynchronizations: boolean = false;
  emailFormControl = new FormControl('', {
    validators: Validators.email,
    asyncValidators: this.validUserEmail(this.authService),
    updateOn: 'change'
  });

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private translate: TranslateService,
    public dialog: MatDialog,
    public authService: AuthService,
    public snackBar: MatSnackBar
  ) {
    this.hasFacebookSynchronizations = environment.synchro?.findIndex((s) => s.type == 'facebook-page') != -1;
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.loadFBSDK();
      this.setupUserInfo();
    }
  }

  //It validates that the user exists
  validUserEmail(authService: AuthService): AsyncValidatorFn {
    this.userFound = null;
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> =>
      authService.getUserInfoByEmail(control.value).pipe(
        map((userFound: User | null) => {
          if (!userFound) {
            return { "userNotFound": true };
          }
          this.userFound = userFound;
          return null;
        }),
        first()
      );
  }

  setupUserInfo(): void {
    let sub = this.authService.getCurrentUserInfo().subscribe(
      (user: User) => {
        sub.unsubscribe();
        if (user) {
          this.user = user;
        }
      }
    )
  }

  //FACEBOOK
  loadFBSDK() {
    (window as any).fbAsyncInit = function() {
      FB.init({
        appId: environment.synchroFacebookAppId,
        xfbml: true,
        version: 'v6.0'
      });
      FB.AppEvents.logPageView();
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  fbLogin() {
    console.log("Log in to facebook");
    FB.login((response) => {
      console.log('submitLogin', response);
      if (response.authResponse) {
        console.log("User Access Token: ", response.authResponse.accessToken);
        this.authService.setFacebookPageAccessToken(response.authResponse).then(() => console.log("User Access Token set successfully"));
      } else {
        console.log('User login failed');
      }
    }, { scope: 'pages_show_list,manage_pages,publish_pages,publish_to_groups' });
  }

  fbLogout() {
    FB.logout((response) => {
      console.log('submitLogout', response);
    });
  }

  //USER MANAGEMENT
  exportData(): void {
    let userSubs = this.authService.user.subscribe((account: any) => {
      if (this.emailFormControl.valid && account && this.userFound) {
        this.authService.gdprRequestExport(this.userFound.uid, this.userFound.email, account.email)
          .then(() => {
            this.emailFormControl.reset();
            this.userFound = null;
            this.snackBar.open(this.translate.instant("Account data export request has been initiated"), null, {
              duration: 4000,
            });
          })
          .catch((error) => {
            console.error(error);
            this.snackBar.open(this.translate.instant("An error occurred while exporting the user account data"), null, {
              duration: 4000,
            });
          });
      } else {
        if (!this.emailFormControl.valid) {
          this.emailFormControl.markAllAsTouched();
        } else {
          this.snackBar.open(this.translate.instant("You must be logged in to export the user account data"), null, {
            duration: 4000,
          });
        }
      }
      userSubs.unsubscribe();
    });
  }

  deleteAccount() {
    let userSubs = this.authService.user.subscribe((account: any) => {
      if (this.emailFormControl.valid && account && this.userFound) {
        let dialogRef = this.dialog.open(
          QuestionWarningComponent, {
            width: '400px',
            data: {
              title: this.translate.instant("Delete account created data"),
              question: this.translate.instant("Do you want to delete the data created by this account (events, organizers, venues and teams)?")
            }
          });
        dialogRef.afterClosed().subscribe(response => {
          this.authService.gdprRequestDelete(this.userFound.uid, this.userFound.email || null, account.email, response)
            .then(() => {
              this.emailFormControl.reset();
              this.userFound = null;
              this.snackBar.open(this.translate.instant("Account deletion request has been initiated"), null, {
                duration: 4000,
              });
            })
            .catch((error) => {
              console.error(error);
              this.snackBar.open(this.translate.instant("An error occurred while deleting the user account"), null, {
                duration: 4000,
              });
            });
        });
      } else {
        if (!this.emailFormControl.valid) {
          this.emailFormControl.markAllAsTouched();
        } else {
          this.snackBar.open(this.translate.instant("You must be logged in to delete the user account"), null, {
            duration: 4000,
          });
        }
      }
      userSubs.unsubscribe();
    });
  }


}
