/// <reference types="googlemaps" />

import { Component, ViewChild, OnInit, Inject, ElementRef } from '@angular/core';

import { Venue } from '../models/venue';
import { FormGroup, FormControl, AsyncValidatorFn, ValidationErrors, AbstractControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { ScriptService } from 'ngx-script-loader';

import { VenueService } from '../services/venue.service';
import { UtilsService } from '../services/utils.service';
import { AuthService } from '../services/auth.service';
import { MapsService } from '../services/maps.service';
import { GeoPoint } from '@angular/fire/firestore';
import { environment } from '../../environments/environment';

import { User } from '../models/user';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-venue',
  templateUrl: './add-venue.component.html',
  styleUrls: ['./add-venue.component.scss']
})
export class AddVenueComponent implements OnInit {

  //Map element
  @ViewChild('gmap') gmapElement: ElementRef;

  //Map
  googleMap: google.maps.Map;
  //Marker
  marker: google.maps.Marker;
  //Map properties
  lat: number = environment.town.lat;
  lng: number = environment.town.lng;
  zoom: number = 17;

  //Address properties
  addresses: any[];
  autoCompleteService: any;
  isLoadingAddresses: boolean;
  addressObj: any;

  maxCharsVenue: number = environment.ui.showOnForm.maxCharsVenue;

  //Form with the two fields
  addVenueForm = new FormGroup({
    name: new FormControl('', {
      validators: Validators.required,
      asyncValidators: this.validVenueName(this.venueService, this.utilsService),
      updateOn: 'change'
    }),
    address: new FormControl('', this.addressFromList),
  });
  formIsWorking: boolean = false;

  currentUserInfo: User;

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<AddVenueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private venueService: VenueService,
    private utilsService: UtilsService,
    private mapsService: MapsService,
    private scriptService: ScriptService,
    private authService: AuthService,
    public snackBar: MatSnackBar) { }

  //INIT
  ngOnInit() {
    //Setup user INFO
    this.initializeUserInfo();
    //Setup google maps for usage
    this.scriptService.runScript('https://maps.googleapis.com/maps/api/js?key=' + environment.firebase.apiKey + "&language=no&libraries=places").subscribe(() => this.onGoogleMapsSDKReady());
  }

  initVenueName() {
    this.addVenueForm.controls["name"].setValue(this.data.venueTitle.trim());
    this.addVenueForm.controls["name"].markAsTouched();
    this.addVenueForm.updateValueAndValidity();
  }

  initMap() {
    let mapProp = {
      center: new google.maps.LatLng(this.lat, this.lng),
      zoom: this.zoom,
    };
    this.googleMap = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    this.marker = new google.maps.Marker({
      position: { lat: this.lat, lng: this.lng },
      map: this.googleMap
    });
    google.maps.event.addListener(this.googleMap, 'drag', () => this.onCenterChange());
    google.maps.event.addListener(this.googleMap, 'dragend', () => this.maybeShowAddressFromLatLng());
  }

  onGoogleMapsSDKReady() {
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.initMap();
    this.addVenueForm.controls["name"].valueChanges
      .subscribe(newVenueName => this.searchByVenueName(newVenueName));
    this.addVenueForm.controls["address"].valueChanges
      .subscribe(newAddress => this.loadAddressSuggestions(newAddress, false));
    this.initVenueName();
  }

  //VALIDATORS
  validVenueName(venueService: VenueService, utilsService: UtilsService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> =>
      venueService.getVenueBySlug(utilsService.slugify(control.value, "-")).pipe(
        map(venue => venue ? { "validVenueName": true } : null),
        first()
      );
  }

  addressFromList(control: FormControl) {
    let selection = control.value;
    if (typeof selection === 'string') {
      return { 'notInList': true }
    }
    return null;
  }

  initializeUserInfo(): void {
    let userInfoSubs = this.authService.getCurrentUserInfo().subscribe(
      (userInfo: User) => {
        this.currentUserInfo = userInfo;
        userInfoSubs.unsubscribe();
      }
    )
  }

  //ADDRESS
  displayWith(obj?: any): string | undefined {
    return obj ? obj.description : undefined;
  }

  displaySuggestions(predictions, status, setAddressInField) {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      this.addresses = predictions;
      if (setAddressInField && predictions && predictions.length > 0) {
        this.addVenueForm.controls["address"].setValue(this.addresses[0]);
      }
    } else {
      this.addresses = [];
    }
    this.addVenueForm.updateValueAndValidity();
    this.isLoadingAddresses = false;
  }

  loadAddressSuggestions(newAddress: string, setAddressInField: boolean): void {
    if (newAddress) {
      this.isLoadingAddresses = true;
      this.autoCompleteService.getPlacePredictions(
        {
          input: newAddress,
          componentRestrictions: {
            country: environment.ui.showOnForm.venueCountry
          },
          location: new google.maps.LatLng(
            environment.town.lat,
            environment.town.lng),
          radius: 10000,
        }, (predictions, status) => this.displaySuggestions(predictions, status, setAddressInField));
    }
  }

  setValidAddress(newAddress: string): void {
    this.loadAddressSuggestions(newAddress, true);
  }

  //MAP
  onAddressSelected(event) {
    if (event.option.value?.description) {
      this.mapsService.getLocation(event.option.value.description).subscribe(
        (response) => {
          if (response && response.status == 'OK' && response.results
            && response.results.length > 0) {
            this.centerMap(response.results[0].geometry.location);
          }
        }
      );
    }
  }
  onCenterChange() {
    this.lat = this.googleMap.getCenter().lat();
    this.lng = this.googleMap.getCenter().lng();
    this.marker.setPosition(new google.maps.LatLng(this.lat, this.lng));
  }

  centerMap(location) {
    this.lat = location.lat;
    this.lng = location.lng;
    this.googleMap.setCenter(new google.maps.LatLng(this.lat, this.lng));
    this.marker.setPosition(new google.maps.LatLng(this.lat, this.lng));
    this.googleMap.setZoom(this.zoom);
  }

  searchByVenueName(newVenueName: string) {
    this.formIsWorking = true;
    this.mapsService.getLocation(newVenueName).subscribe(
      (response) => {
        if (response?.status == 'OK' && response.results?.length > 0) {
          this.centerMap(response.results[0].geometry.location);
          let newAddress = response.results[0].formatted_address;
          this.addressObj = this.getAddressObj(response.results[0].address_components);
          this.setValidAddress(newAddress);
        }
        this.formIsWorking = false;
      }
    );
  }

  maybeShowAddressFromLatLng() {
    this.mapsService.getAddress(this.lat, this.lng)
      .subscribe(
        result => {
          if (result.status == 'OK') {
            this.addVenueForm.controls["address"].setValue(result.results[0].formatted_address);
            this.addressObj = this.getAddressObj(result.results[0].address_components);
            let newAddress = result.results[0].formatted_address;
            this.setValidAddress(newAddress);
          } else {
            this.addVenueForm.controls["address"].setValue(null);
          }
        },
        () => {
          this.addVenueForm.controls["address"].setValue(null);
        });
  }

  getAddressObj(addressComponents: any[]): any {
    return {
      street: this.getAddressComponent(addressComponents, 'route'),
      number: this.getAddressComponent(addressComponents, 'street_number'),
      postal: this.getAddressComponent(addressComponents, 'postal_code'),
      municipality: this.getAddressComponent(addressComponents, 'administrative_area_level_2'),
      region: this.getAddressComponent(addressComponents, 'administrative_area_level_1'),
      country: this.getAddressComponent(addressComponents, 'country'),
    }

  }

  getAddressComponent(components: any, name: string) {
    const filtered: any[] = components?.filter((comp: any) => comp.types?.indexOf(name) != -1) || [];
    if (filtered.length > 0) {
      return filtered[0].long_name || '';
    }
    return '';
  }

  //ACTIONS
  onCancel() {
    this.dialogRef.close();
  }

  //Prevent closing the dialog when the user press Enter focused on the "name" field
  preventClosing(e) {
    e.preventDefault();
  }

  venueFromForm(): Venue {
    //Gather all the form values
    let newVenue: Venue = this.addVenueForm.value;
    newVenue.name = newVenue.name.charAt(0).toUpperCase() + newVenue.name.slice(1);
    newVenue.address = this.addVenueForm.controls["address"].value.description;
    newVenue.addressObj = this.addressObj || null;
    newVenue.author_id = this.currentUserInfo?.uid || '';
    newVenue.created_at = new Date();
    //Gather the coordinates from the map
    newVenue.location = new GeoPoint(this.lat, this.lng);
    newVenue.slug = this.utilsService.slugify(newVenue.name, '-');
    return newVenue;
  }

  addVenue() {
    this.formIsWorking = true;
    if (this.addVenueForm.valid) {
      this.venueService.addVenue(this.venueFromForm()).subscribe(
        docRef => {
          this.dialogRef.close(docRef.id);
          this.formIsWorking = false;
          this.snackBar.open(this.translate.instant("Venue added successfully"), null, {
            duration: 4000,
          });
        },
        error => {
          this.formIsWorking = false;
          console.error(error);
          this.snackBar.open(this.translate.instant("There was an error while creating your venue"), null, {
            duration: 4000,
          });
        }
      );
    } else {
      this.formIsWorking = false;
    }
  }

}
