<div *ngIf="environment.theme == 'classic'" class="classic" fxLayout="column" fxLayoutAlign="start center">
  <div class="full-width" fxLayout="column" fxLayoutAlign="start center">
    <a routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }" routerLink="/" fxLayout="row"
      class="menu-item" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-icon>home</mat-icon>
      <div translate>Events</div>
    </a>
  </div>
  <div *ngFor="let menuItem of (environment?.ui?.showOnMenu || [])" class="full-width" fxLayout="column"
    fxLayoutAlign="start center">
    <a *ngIf="menuItem.internal" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class="menu-item"
      [routerLinkActiveOptions]="{ exact: true }" [routerLink]="menuItem.link" routerLinkActive="active-link"
      [fxShow]="((!menuItem.loggedIn || (menuItem.loggedIn && user)) || (!menuItem.admin || (menuItem.admin && user?.roles?.admin)))">
      <mat-icon>{{menuItem.icon}}</mat-icon>
      <div>{{menuItem['name_' + translate.currentLang]}}</div>
    </a>
    <a *ngIf="!menuItem.internal" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class="menu-item"
      [href]="menuItem.link" target="_blank">
      <mat-icon>{{menuItem.icon}}</mat-icon>
      <div>{{menuItem['name_' + translate.currentLang]}}<img src="assets/icons/open_in_new.svg" class="external-link">
      </div>
    </a>
  </div>
  <div class="full-width" fxLayout="column" fxLayoutAlign="start center">
    <a *ngIf="!user" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }" routerLink="/login"
      fxLayout="row" class="menu-item" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-icon>person</mat-icon>
      <div translate>Sign In / Register</div>
    </a>
    <a *ngIf="user" (click)="logOut()" fxLayout="row" class="menu-item" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-icon>exit_to_app</mat-icon>
      <div translate>Sign Out</div>
    </a>
  </div>
</div>

<div *ngIf="environment.theme == 'trdevents'" class="trdevents" fxLayout="column" fxLayoutAlign="start center"
  fxLayoutGap="30px">
  <div class="full-width" fxLayout="column" fxLayoutAlign="start center">

    <div *ngIf="environment.language.multilanguage">
      <button class="language-button" *ngIf="translate.currentLang == 'nb'" mat-icon-button
        (click)="drawer ? drawer.close() : null" (click)="switchLanguage('en')">EN</button>
      <button class="language-button" *ngIf="translate.currentLang != 'nb'" mat-icon-button
        (click)="drawer ? drawer.close() : null" (click)="switchLanguage('nb')">NO</button>
    </div>

    <a class="menu-item" (click)="drawer ? drawer.close() : null"
      [ngClass]="{ 'sub-category': category.parentCategory != null, 'hidden': selectedCat && (selectedCat == category.parentCategory) }"
      *ngFor="let category of allCategories" fxLayout="row" fxLayoutAlign="space-between center"
      routerLinkActive="active-link" fragment="top" [routerLink]=" ['/', 'category', category.slug]">
      <span>{{category.name}}</span>
      <img src="/assets/icons/arrow_circle.png" />
    </a>

    <a *ngIf="otherCategory" class="menu-item" fxLayout="row" fxLayoutAlign="space-between center"
      (click)="othetCategoriesExpanded = !othetCategoriesExpanded">
      <span>{{otherCategory.name}}</span>
      <img src="/assets/icons/arrow_circle.png" />
    </a>
    <div *ngIf="othetCategoriesExpanded" class="full-width">
      <a class="menu-item sub-category" *ngFor="let category of otherCategories" fxLayout="row"
        (click)="drawer ? drawer.close() : null" fxLayoutAlign="space-between center" routerLinkActive="active-link"
        fragment="top" [routerLink]=" ['/', 'category', category.slug]">
        <span>{{category.name}}</span>
        <img src="/assets/icons/arrow_circle.png" />
      </a>
    </div>

    <a *ngIf="user" class="menu-item" fxLayout="row" fxLayoutAlign="space-between center" routerLinkActive="active-link"
      (click)="drawer ? drawer.close() : null" [routerLink]="['/', 'organizers', {outlets: {organizers: 'my-events'}}]">
      <span>{{'My events' | translate}}</span>
      <img src="/assets/icons/arrow_circle.png" />
    </a>
    <a *ngIf="user" class="menu-item" fxLayout="row" fxLayoutAlign="space-between center" routerLinkActive="active-link"
      (click)="drawer ? drawer.close() : null" [routerLink]="['/', 'organizers', {outlets: {organizers: 'add-an-event'}}]">
      <span>{{'Add an event' | translate}}</span>
      <img src="/assets/icons/arrow_circle.png" />
    </a>
    <a *ngIf="user" class="menu-item" fxLayout="row" fxLayoutAlign="space-between center" routerLinkActive="active-link"
      (click)="drawer ? drawer.close() : null" [routerLink]="['/', 'organizers', {outlets: {organizers: 'my-teams'}}]">
      <span>{{'My teams' | translate}}</span>
      <img src="/assets/icons/arrow_circle.png" />
    </a>
    <a *ngIf="user" class="menu-item" fxLayout="row" fxLayoutAlign="space-between center" routerLinkActive="active-link"
      (click)="drawer ? drawer.close() : null" (click)="logOut()">
      <span>{{'Log out' | translate}}</span>
      <img src="/assets/icons/arrow_circle.png" />
    </a>
    <a *ngIf="!user" class="menu-item" fxLayout="row" fxLayoutAlign="space-between center"
      (click)="drawer ? drawer.close() : null" routerLinkActive="active-link"
      [routerLink]="['/', 'organizers', {outlets: {organizers: 'login'}}]">
      <span>{{'Login organizers' | translate}}</span>
      <img src="/assets/icons/arrow_circle.png" />
    </a>
    <a class="menu-item" fxLayout="row" fxLayoutAlign="space-between center" routerLinkActive="active-link"
      (click)="drawer ? drawer.close() : null" [routerLink]="['/', 'organizers', {outlets: {organizers: 'about-us'}}]">
      <span>{{'About us' | translate}}</span>
      <img src="/assets/icons/arrow_circle.png" />
    </a>
  </div>

</div>