import { Component, OnInit, ViewChild, ElementRef, Input, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, AsyncValidatorFn, ValidationErrors, AbstractControl, FormArray, ValidatorFn, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith, first, catchError, debounceTime } from 'rxjs/operators';
import { NgxImageCompressService } from 'ngx-image-compress';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { Venue } from '../models/venue';
import { Organizer } from '../models/organizer';
import { Event } from '../models/event';
import { Category } from '../models/category';
import { EventRepetition } from '../models/event-repetition';
import { SelectableEvent } from '../models/selectable-event';
import { environment } from '../../environments/environment';

import { ImportFacebookComponent } from '../import-facebook/import-facebook.component';

import { AddVenueComponent } from '../add-venue/add-venue.component';
import { AddOrganizerComponent } from '../add-organizer/add-organizer.component';
import { AddRecurrenceComponent } from '../add-recurrence/add-recurrence.component';

import { EventService } from '../services/event.service';
import { EventListService } from '../services/event-list.service';
import { CategoryService } from '../services/category.service';
import { VenueService } from '../services/venue.service';
import { OrganizerService } from '../services/organizer.service';
import { AuthService } from '../services/auth.service';
import { UtilsService } from '../services/utils.service';
import { TranslateService } from '@ngx-translate/core';


import moment from 'moment';
import { Team } from '../models/team';
import { TeamService } from '../services/team.service';
import { AddTeamComponent } from '../add-team/add-team.component';
import { User } from '../models/user';
import { QuestionWarningComponent } from '../question-warning/question-warning.component';
import { ImageObject } from '../models/image-object';
import { ActivityType } from '../models/activity-type';
import { ActivityTypeService } from '../services/activityType.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { of, Subscription } from 'rxjs';
import { _MatAutocompleteBase } from '@angular/material/autocomplete';
import { TicketType } from '../models/ticket-type';
import { TicketTypeService } from '../services/ticket-type.service';
import { Title } from '@angular/platform-browser';
import { AddTicketTypeComponent } from '../add-ticket-type/add-ticket-type.component';


@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class EventFormComponent implements OnInit, OnDestroy {

  @Input()
  event: Event;
  originalEvent: Event = null;

  @Input()
  isEdit: boolean = false;

  regexpHour = /^\d{1,2}:\d{2}$/i;
  regexpURL = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  regexpFacebookEventURL = /^(?:https?\:\/\/)?(?:www\.)?(m\.)?facebook\.com\/events\/\d{10,20}((\/|\?).*)?$/i;
  regexpEmbeddedVideoYoutoubeURL = /^(http:\/\/|https:\/\/)(vimeo\.com|youtu\.be|www\.youtube\.com)\/([\w\/]+)([\?].*)?$/i;
  regexpEmbeddedVideoVimeoURL = /^(http:\/\/|https:\/\/)(vimeo\.com|youtu\.be|www\.youtube\.com)\/([\w\/]+)([\?].*)?$/i;
  regexpEmbeddedPanoptoURL = /^(https:\/\/ntnu\.cloud\.panopto\.eu\/Panopto\/Pages\/Embed\.aspx\?id=)([\-\w])+([\w&\?=]*)?$/i;

  loading: boolean = false;
  uploading: boolean = false;
  maxCharsTitle: number = environment.ui.showOnForm.maxCharsTitle;
  maxCharsDesc: number = environment.ui.showOnForm.maxCharsDesc;
  maxCharsSummary: number = environment.ui.showOnForm.maxCharsSummary;
  maxCharsVenueNotes: number = 30;
  maxNumImages: number = 5;
  maxRepetitions: number = 365;

  eventForm = new FormGroup({
    ageRestriction: new FormControl('no-restriction', []),
    activeTickets: new FormControl(0),
    automaticWaitingList: new FormControl(true),
    availableTickets: new FormControl(),
    desc_en: new FormControl('', [Validators.maxLength(this.maxCharsDesc)]),
    desc_nb: new FormControl('', [Validators.maxLength(this.maxCharsDesc)]),
    duration: new FormControl('0', []),
    eatTogether: new FormControl(false, []),
    editableBy: new FormControl(environment.ui?.defaults?.editableBy || 'only-author', []),
    embeddedVideoURL: new FormControl('', [
      this.videoEmbeddedValidator(),
    ]),
    endDate: new FormControl('', []),
    endTime: new FormControl('', [Validators.pattern(this.regexpHour)]),
    eventCancelled: new FormControl(false, []),
    eventSoldOut: new FormControl(false, []),
    facebookURL: new FormControl('', [Validators.pattern(this.regexpFacebookEventURL)]),
    fieldEvent: new FormControl(false, []),
    images: new FormArray([], [Validators.required, Validators.maxLength(5)]),
    isFeaturedEvent: new FormControl(false, []),
    cancellationPeriod: new FormControl(7, [Validators.required]),
    maximumAge: new FormControl('', [Validators.min(1), Validators.max(99)]),
    minimumAge: new FormControl('', [Validators.min(1), Validators.max(99)]),
    moreInfoURL: new FormControl('', [Validators.pattern(this.regexpURL)]),
    mode: new FormControl('offline', []),
    noTicketsInfo: new FormControl(true),
    organizers: new FormArray([
      new FormGroup({
        organizer: new FormControl('', []),
        organizerObj: new FormGroup({
          author_id: new FormControl('', []),
          id: new FormControl('', []),
          slug: new FormControl('', []),
          email: new FormControl('', [Validators.email]),
          name: new FormControl('', []),
          telephoneNumber: new FormControl('', []),
          website: new FormControl('', []),
        })
      })
    ], { validators: this.organizersValidator() }),
    paymentMethod: new FormControl('door'),
    priceOption: new FormControl('non-gratis', []),
    publishingDate: new FormControl('', []),
    publishingOption: new FormControl('now', []),
    publishingRules: new FormControl('', [Validators.requiredTrue]),
    publishingTime: new FormControl('9:00', [Validators.pattern(this.regexpHour)]),
    prices: new FormArray([
      new FormGroup({
        type: new FormControl('REGULAR'),
        name_nb: new FormControl(),
        name_en: new FormControl(),
        price: new FormControl(null, [Validators.min(0)]),
      })
    ], { validators: this.pricesValidator() }),
    registrationEnabled: new FormControl('', []),
    startDate: new FormControl('', []),
    startTime: new FormControl('12:00', [Validators.pattern(this.regexpHour)]),
    streamingURL: new FormControl('', [Validators.pattern(this.regexpURL)]),
    super_event: new FormControl('', []),
    summary_en: new FormControl('', []),
    summary_nb: new FormControl('', []),
    status: new FormControl('', []),
    ticketsURL: new FormControl('', [Validators.pattern(this.regexpURL)]),
    ticketsFromDate: new FormControl(new Date()),
    ticketsFromTime: new FormControl('9:00', [Validators.pattern(this.regexpHour)]),
    title_en: new FormControl('', []),
    title_nb: new FormControl('', {
      validators: Validators.required,
      asyncValidators: this.validEventTitle(this.eventService),
      updateOn: 'change'
    }),
    type: new FormControl('orphan', []),
    updated_at: new FormControl('', []),
    venue: new FormControl('', []),
    venueNote: new FormControl('', []),
    venueObj: new FormControl('', []),
    videosURL: new FormControl('', [Validators.pattern(this.regexpURL)]),
  });

  @ViewChild("eventFormContainer")
  eventFormContainer: ElementRef;

  hasSuper: boolean = false;
  isSuper: boolean = false;
  environment: any;

  //Excerpt and description
  Editor;
  configEditorNb;
  configEditorEn;
  isBrowser = false;

  //Image fields
  //Declaring FormArray inside of GroupArray
  images = this.eventForm.get('images') as FormArray;
  imageAsDataURL: string;

  //Categories
  categories: Category[];
  selectedCategories: Set<string> = new Set([]);
  categoryRequired: boolean = false;
  categoryTooMany: boolean = false;


  //Event tags
  eventTags: string[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  //Activity types
  allActivityTypes: ActivityType[];
  selectedActivityTypes: Set<string> = new Set([]);
  activityTypeRequired: boolean = false;
  activityTypeTooMany: boolean = false;

  //Venue
  venueFilterCtrl: FormControl = new FormControl();
  newVenue: string;
  venues: Venue[];
  filteredVenues: Observable<Venue[]>;

  //Mazemap
  poi: string;
  showMazemap: boolean = false;

  //Organizer
  organizerFilterCtrl: FormControl = new FormControl();
  newOrganizer: string;
  organizers: Organizer[];
  filteredOrganizers: Observable<Organizer[]>;

  //Dates and hours
  minDate = new Date();
  minPublishingDate = new Date();
  minEndDate = new Date();
  hours: SelectableEvent[];
  filteredStartHours: Observable<any[]>;
  filteredEndHours: Observable<any[]>;
  hourToHighlight: string = '';
  durationStr: string;

  //Prices
  allTicketTypes: TicketType[];
  allTicketsAreFree: boolean = true;

  //Super Events
  superEvents: Event[];

  //Event repetitions
  eventRepetitions: EventRepetition[] = [];
  errorRepetitionsVenue: boolean;

  //Synchro destinations
  synchroDestinations: string[] = environment.synchro.filter((syn: any) => syn.checked).map((syn: any) => syn.destination);
  selectedSynchronizations: Set<string> = new Set(this.synchroDestinations);
  isValidForSynchronizing: boolean[] = [];

  //Editable by
  teams$: Observable<Team[]>;
  currentUserInfo: User;
  userId: string;
  userInfoSubs: Subscription;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private eventService: EventService,
    private imageCompress: NgxImageCompressService,
    private fb: FormBuilder,
    private eventListService: EventListService,
    private categoryService: CategoryService,
    private ticketTypeService: TicketTypeService,
    private activityTypeService: ActivityTypeService,
    private venueService: VenueService,
    private titleService: Title,
    private teamService: TeamService,
    private organizerService: OrganizerService,
    public authService: AuthService,
    private utilsService: UtilsService,
    private translate: TranslateService,
    public snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private adapter: DateAdapter<any>,
    public dialog: MatDialog,
    private http: HttpClient) {
  }
  
  ngOnInit() {
    //Setup form components
    this.initializeComponents();
    //Setup user info
    this.initializeUserInfo();
    //Subscribe to the language change event
    this.translate.onLangChange.subscribe(() => {
      //If the language change while we are on this screen
      this.initializeComponents();
    });
    //Setup event info
    if (this.event) {
      this.titleService.setTitle(this.translate.instant("Edit event") + ' | ' + environment.content.siteName);
      this.fillDataFromEvent(this.event);
    } else {
      this.titleService.setTitle(this.translate.instant("Add an event") + ' | ' + environment.content.siteName);
    }
  }

  ngOnDestroy() {
    this.userInfoSubs.unsubscribe();
  }

  //VALIDATORS

  //It validates that the event title is unique
  validEventTitle(eventService: EventService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> =>
      eventService.existsEventWithSameTitle(control.value, this.route.snapshot.params.id || null).pipe(
        map(exists => exists ? { "validEventTitle": true } : null),
        first()
      );
  }

  //It validates that we have one or more organizers and all of them are distinct
  organizersValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value.length > 1) {
        const allDistinct = new Set(control.value.map(ele => ele.organizer)).size == control.value.length;
        if (!allDistinct) {
          return { 'allDistinct': true };
        }
      }
      return null;
    };
  }

  buildOrganizersControls(organizersControl: FormArray, organizers: any[]) {
    if (organizers) {
      for (let i = 1; i < organizers.length; i++) {
        organizersControl.push(
          new FormGroup({
            organizer: new FormControl('', []),
            organizerObj: new FormGroup({
              author_id: new FormControl('', []),
              id: new FormControl('', []),
              slug: new FormControl('', []),
              email: new FormControl('', [Validators.email]),
              name: new FormControl('', []),
              telephoneNumber: new FormControl('', []),
              website: new FormControl('', []),
            })
          })
        )
      };
    }
  }

  //It validates that we have one or more prices and all of them are distinct
  pricesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value.length > 1) {
        const allDistinct = new Set(control.value.map(ele => ele.type)).size == control.value.length;
        if (!allDistinct) {
          return { 'allDistinct': true };
        }
      }
      return null;
    };
  }

  anyPriceEmpty(): boolean {
    if (this.eventForm?.touched && !this.eventForm?.value?.noTicketsInfo && this.eventForm?.value?.prices) {
      return this.eventForm?.value?.prices?.filter(p => p.price != 0 && p.price != null)?.length != this.eventForm?.value?.prices.length;
    }
    return false;
  }


  buildPricesControls(pricesControl: FormArray, event: Event) {
    if (event.prices?.length > 0) {
      for (let i = 1; i < event.prices.length; i++) {
        pricesControl.push(
          new FormGroup({
            type: new FormControl('', [Validators.required]),
            price: new FormControl('', [Validators.min(0), Validators.required]),
            name_nb: new FormControl(),
            name_en: new FormControl(),
          })
        )
      };
    } else {
      //DEPRECATED
      if (event.regularPrice > 0) {
        pricesControl.at(0).setValue({
          type: 'REGULAR',
          price: event.regularPrice,
          name_nb: new FormControl(),
          name_en: new FormControl(),
        });
      }
      if (event.reducedPrice > 0) {
        pricesControl.push(
          new FormGroup({
            type: new FormControl('REDUCED', [Validators.required]),
            price: new FormControl(event.reducedPrice, [Validators.min(0), Validators.required]),
            name_nb: new FormControl(),
            name_en: new FormControl(),
          })
        )
      }
    }
  }

  videoEmbeddedValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      if (this.regexpEmbeddedVideoYoutoubeURL.test(control.value)) {
        return null;
      }
      if (this.regexpEmbeddedVideoVimeoURL.test(control.value)) {
        return null;
      }
      if (this.regexpEmbeddedPanoptoURL.test(control.value)) {
        return null;
      }
      if (control.value.indexOf("youtu") != -1) {
        return { invalidYoutubeURL: true };
      }
      if (control.value.indexOf("vimeo") != -1) {
        return { invalidVimeoURL: true };
      }
      if (control.value.indexOf("panopto") != -1) {
        return { invalidPanoptoURL: true };
      }
      //TODO: If none of the previous, return an error that must be a valid source
    };
  }

  fillDataFromEvent(event: Event) {
    //Original event entity
    this.originalEvent = event;
    //Build the organizers control array depending on the number of organizers
    this.buildOrganizersControls((<FormArray>this.eventForm.get("organizers")), event.organizers);
    //Build the prices control array depending on the number of organizers
    this.buildPricesControls((<FormArray>this.eventForm.get("prices")), event);
    //Set form values
    this.eventForm.patchValue(event);
    //Evaluate if all prices are free
    this.evaluateIfAllTicketsAreFree();
    //Set the start date
    if (event.startDate) {
      this.eventForm.controls["startDate"].setValue(event.startDate.toDate());
      if (this.minDate > event.startDate.toDate()) {
        this.minDate = event.startDate.toDate();
      }
    }
    //Set categories
    this.selectedCategories = new Set(event.categories);
    //Set activity types
    this.selectedActivityTypes = (event.activityTypes?.length > 0 && new Set(event.activityTypes.split(","))) || new Set([]);
    //Set free tagging
    this.eventTags = (event.tags?.length > 0 && event.tags.split(",")) || [];
    //Mazemap poi
    this.poi = event.mazeMapPoi;
    //Has Super?
    this.hasSuper = event.type == 'has-super';
    this.isSuper = event.type == 'super-event';
    if (this.isSuper) {
      this.eventForm.controls['endDate'].enable();
      this.eventForm.controls['startTime'].disable();
      this.eventForm.controls["endDate"].setValue(event.endDate.toDate());
    } else {
      if (event.duration && !event.endTime) {
        this.eventForm.controls["endTime"].setValue(moment(event.endDate.toDate()).format('H:mm'));
        this.eventForm.controls["duration"].setValue(event.duration.toString());
      }
      this.eventForm.controls['startTime'].enable();
      this.eventForm.controls['endDate'].disable();
    }
    //Init the repetitions
    this.eventRepetitions = [];
    if (event.repetitions?.length > 0) {
      //Remove the first repetition, which represents the date in our form
      this.eventRepetitions = event.repetitions.slice(1);
    }

    //Synchronizations: fill with data if we have them
    if (event.synchroDestinations != undefined) {
      this.synchroDestinations = event.synchroDestinations;
      this.selectedSynchronizations = new Set(this.synchroDestinations);
    }
    //Publishing options
    if (event.publishingDate) {
      this.eventForm.controls["publishingDate"].setValue(event.publishingDate.toDate());
      if (this.minPublishingDate > event.publishingDate.toDate()) {
        this.minPublishingDate = event.publishingDate.toDate();
      }
    }

    //Tickets options
    if (event.ticketsFromDate) {
      this.eventForm.controls["ticketsFromDate"].setValue(event.ticketsFromDate.toDate());
    }

    //Images
    if (event.images) {
      const imagesControls = this.fb.array(event.images.map(
        (image: ImageObject) => new FormGroup({
          alt: new FormControl(image.alt),
          caption: new FormControl(image.caption),
          credits: new FormControl(image.credits),
          largeSize: new FormControl(image.largeSize, []),
          originalSize: new FormControl(image.originalSize, []),
          smallSize: new FormControl(image.smallSize, []),
          urlLarge: new FormControl(image.urlLarge, []),
          urlSmall: new FormControl(image.urlSmall, []),
          urlOriginal: new FormControl(image.urlOriginal, [])
        })
      ));
      this.eventForm.setControl('images', imagesControls);
      this.images = this.eventForm.get('images') as FormArray;
    }
  }

  initializeComponents() {
    this.environment = environment;
    // For the datepicker component
    this.setAdapterLocale(this.translate.currentLang);
    this.hours = this.utilsService.getAllHours();
    this.initCategories();
    this.initTicketTypes();
    this.initActivityTypes();
    this.initVenues();
    this.initOrganizers();
    this.initSuperEvents();
    this.initFilterHours();
    this.initTeams();
    this.initSynchroValidators();
    this.initSynchronizationOptions();
    this.initDescriptions();
  }

  initializeUserInfo(): void {
    this.userInfoSubs = this.authService.getCurrentUserInfo().subscribe(
      (userInfo: User) => {
        if (userInfo) {
          this.userId = userInfo.uid;
          this.currentUserInfo = userInfo;
        }
      }
    );
  }

  setAdapterLocale(language: string) {
    if (language != 'nb') {
      this.adapter.setLocale('en-GB');
    } else {
      this.adapter.setLocale(language);
    }
  }

  /* IMPORT FROM FACEBOOK */
  importFromFacebook() {
    let dialogRef = this.dialog.open(
      ImportFacebookComponent, {
      width: '500px'
    });
    dialogRef.afterClosed().subscribe(fbEvent => {
      if (fbEvent && !fbEvent.error) {
        this.fillDataFromEvent(fbEvent);
        if (fbEvent.imageBase64) {
          this.imageAsDataURL = fbEvent.imageBase64;
          this.images.push(this.fb.group({
            alt: new FormControl(fbEvent.imageCaption),
            caption: new FormControl(''),
            credits: new FormControl(''),
            originalSize: new FormControl('', null),
            largeSize: new FormControl('', null),
            smallSize: new FormControl('', null),
            urlLarge: new FormControl('', null),
            urlSmall: new FormControl('', null),
            urlOriginal: new FormControl('', null)
          }));
        }
      }
    });
  }

  /*DESCRIPTIONS*/
  initDescriptions() {
    if (isPlatformBrowser(this.platformId)) {
      const ClassicEditor = require('@ckeditor/ckeditor5-build-classic');
      this.Editor = ClassicEditor;
      this.configEditorNb = {
        placeholder: this.translate.instant("Event description in Norwegian"),
        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
        heading: {
          options: [
            { model: 'paragraph', title: this.translate.instant("Normal text"), class: 'ck-heading_paragraph' },
            { model: 'heading2', view: 'h2', title: this.translate.instant("Heading 2"), class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: this.translate.instant("Heading 3"), class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: this.translate.instant("Heading 4"), class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: this.translate.instant("Heading 5"), class: 'ck-heading_heading5' }
          ]
        }
      };
      this.configEditorEn = {
        placeholder: this.translate.instant("Event description in English"),
        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
        heading: {
          options: [
            { model: 'paragraph', title: this.translate.instant("Normal text"), class: 'ck-heading_paragraph' },
            { model: 'heading2', view: 'h2', title: this.translate.instant("Heading 2"), class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: this.translate.instant("Heading 3"), class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: this.translate.instant("Heading 4"), class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: this.translate.instant("Heading 5"), class: 'ck-heading_heading5' }
          ]
        }
      };
      this.isBrowser = true;
    }
  }

  /* ACTIVITY TYPES */
  initActivityTypes() {
    this.allActivityTypes = this.activityTypeService.getAllActivityTypes();
  }

  changeActivityType(event) {
    //Set the selected Activity Types
    if (event.checked) {
      this.selectedActivityTypes.add(event.source.value);
    } else {
      this.selectedActivityTypes.delete(event.source.value);
    }
    this.maybeShowActivityTypeErrors();
  }

  maybeShowActivityTypeErrors() {
    this.activityTypeRequired = this.selectedActivityTypes.size == 0;
    this.activityTypeTooMany = this.selectedActivityTypes.size > environment.ui.showOnForm.maxActivityType;
  }


  /* CATEGORIES */
  initCategories() {
    this.categories = this.categoryService.getAllFlattenCategories();
  }

  changeCategory(event) {
    //Set the selected categories
    if (event.checked) {
      this.selectedCategories.add(event.source.value);
    } else {
      this.selectedCategories.delete(event.source.value);
    }
    this.maybeShowCategoryErrors();
  }

  maybeShowCategoryErrors() {
    this.categoryRequired = this.selectedCategories.size == 0;
    this.categoryTooMany = environment.ui.showOnForm.maxCategories != -1 && this.selectedCategories.size > environment.ui.showOnForm.maxCategories;
  }

  /*TAGS*/
  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    const index = this.eventTags?.indexOf(value);

    if (index === -1 && this.eventTags.length < environment.ui.showOnForm.maxTags) {
      // Add a member
      if ((value || '').trim()) {
        this.eventTags.push(value.trim());
      }
      // Reset the input value
      if (input) {
        input.value = '';
      }
    }
  }

  removeTag(tag: string): void {
    const index = this.eventTags?.indexOf(tag);

    if (index >= 0) {
      this.eventTags.splice(index, 1);
    }
  }

  /* MODE */
  onEventModeChange(e) {
    if (!e.checked) {
      this.eventForm.controls['venue'].enable();
      this.eventForm.controls.mode.setValue('offline');
    } else {
      this.eventForm.controls['venue'].setValue(null);
      this.eventForm.controls["venueObj"].setValue(null);
      this.eventForm.controls['venue'].disable();
      this.eventForm.controls.mode.setValue('online');
    }
    //Update the repetitions if there is any
    this.eventRepetitions = this.eventRepetitions.map((rep) => ({
      ...rep,
      mode: e.checked ? 'online' : 'offline'
    }));
    this.eventForm.controls['venue'].updateValueAndValidity();
  }

  /*VENUE*/
  initVenues() {
    this.setupNewVenueOption();
    this.venueService.getAllVenues()
      .subscribe((venuesOrdered) => {
        this.venues = venuesOrdered;
        this.filteredVenues = this.venueFilterCtrl.valueChanges
          .pipe(
            startWith<string | Venue>(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name.trim() ? this._filterVenues(name) : this.venues.slice())
          );
      });
  }

  private _filterVenues(name: string): Venue[] {
    const filterValue = name.toLowerCase();

    return this.venues.filter(option => option.name?.toLowerCase()?.indexOf(filterValue) != -1);
  }

  setupNewVenueOption() {
    this.venueFilterCtrl.valueChanges
      .subscribe((newVenueName) => {
        this.newVenue = newVenueName;
      });
  }

  createVenue() {
    let dialogRef = this.dialog.open(
      AddVenueComponent, {
      width: '500px',
      data: {
        venueTitle: this.newVenue
      }
    });

    dialogRef.afterClosed().subscribe(venueID => {
      this.eventForm.controls["venue"].patchValue(venueID);
      let venueObj = this.venues.find(v => v.id == venueID) || null;
      this.eventForm.controls["venueObj"].patchValue(venueObj);
    });
  }

  onVenueChange($event: MatSelectChange): void {
    let venueObj = this.venues.find(v => v.id == $event.value) || null;
    this.eventForm.controls["venueObj"].patchValue(venueObj);
    this.eventRepetitions = this.eventRepetitions.map((rep) => ({
      ...rep,
      venue: $event.value,
      venueObj: venueObj
    }));
    if (this.currentUserInfo?.usedVenueNotes && this.currentUserInfo.usedVenueNotes[$event.value]) {
      this.eventForm.controls["venueNote"].patchValue(this.currentUserInfo.usedVenueNotes[$event.value]);
    }
  }

  /*MAZEMAP*/
  onPoiChange(newPoi: string) {
    this.poi = newPoi;
  }
  onLocationEvaluated(show: boolean) {
    this.showMazemap = show;
  }

  /* ORGANIZERS */
  initOrganizers() {
    this.setupNewOrganizerOption();
    this.organizerService.getAllOrganizers()
      .subscribe((organizersOrdered) => {
        this.organizers = organizersOrdered;
        this.filteredOrganizers = this.organizerFilterCtrl.valueChanges
          .pipe(
            startWith<string | Organizer>(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filterOrganizers(name) : this.organizers.slice())
          );
        if (!this.event && environment.ui?.defaults?.organizerID) {
          (<FormGroup>(<FormArray>this.eventForm.get('organizers'))?.at(0))?.controls["organizer"]?.patchValue(environment.ui.defaults?.organizerID);
          let organizerObj = this.getOrganizerObject(environment.ui.defaults?.organizerID);
          if (organizerObj) {
            (<FormGroup>(<FormArray>this.eventForm.get('organizers'))?.at(0))?.controls["organizerObj"]?.patchValue(organizerObj);
          }
        }
      });
  }

  private _filterOrganizers(name: string): Organizer[] {
    const filterValue = name.toLowerCase();
    return this.organizers.filter(option => option.name?.toLowerCase()?.indexOf(filterValue) != -1);
  }

  setupNewOrganizerOption() {
    this.organizerFilterCtrl.valueChanges
      .subscribe((newOrganizerName) => {
        this.newOrganizer = newOrganizerName;
      });
  }

  createOrganizer(index: number) {
    let dialogRef = this.dialog.open(
      AddOrganizerComponent, {
      width: '500px',
      data: {
        organizerTitle: this.newOrganizer
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      (<FormGroup>(<FormArray>this.eventForm.get('organizers'))?.at(index))?.controls["organizer"]?.patchValue(result);
      let organizerObj = this.getOrganizerObject(result);
      if (organizerObj) {
        (<FormGroup>(<FormArray>this.eventForm.get('organizers'))?.at(index))?.controls["organizerObj"]?.patchValue(organizerObj);
      }
    });
  }

  onOrganizerChange($event: MatSelectChange, index: number): void {
    let organizerObj: Organizer = this.getOrganizerObject($event.value);
    if (organizerObj) {
      (<FormGroup>(<FormArray>this.eventForm.get('organizers'))?.at(index))?.controls["organizerObj"]?.patchValue(organizerObj);
    }
  }

  removeOrganizer(i: number) {
    (<FormArray>this.eventForm.get('organizers')).removeAt(i);
  }

  addOrganizer() {
    (<FormArray>this.eventForm.get('organizers')).push(new FormGroup({
      organizer: new FormControl('', []),
      organizerObj: new FormGroup({
        author_id: new FormControl('', []),
        id: new FormControl('', []),
        slug: new FormControl('', []),
        email: new FormControl('', [Validators.email]),
        name: new FormControl('', []),
        telephoneNumber: new FormControl('', []),
        website: new FormControl('', []),
      })
    }));
  }

  /* SUPER EVENTS */
  initSuperEvents(): void {
    this.eventService.getAllSuperEvents().pipe(first()).subscribe(
      (superEvents) => {
        this.superEvents = superEvents;
      }
    );
  }

  /* HOURS */
  initFilterHours(): void {
    this.filteredStartHours = this.eventForm.controls.startTime.valueChanges
      .pipe(
        startWith(''),
        map(userInput => this.maybeSetDuration() && userInput),
        map(userInput => this.filterHours(userInput))
      );
    this.filteredEndHours = this.eventForm.controls.endTime.valueChanges
      .pipe(
        startWith(''),
        map(userInput => this.maybeSetDuration() && userInput),
        map(userInput => this.filterHours(userInput))
      );
  }

  filterHours(userInput: string) {
    this.hourToHighlight = userInput;
    return this.hours.filter(hour => hour.value?.indexOf(userInput) === 0);
  }

  getCurrentDurationInMilliseconds(): number {
    if (this.eventForm.controls.startTime.valid
      && this.eventForm.controls.endTime.valid
      && this.eventForm.controls.startTime.value
      && this.eventForm.controls.endTime.value) {
      const startStr: string = this.eventForm.controls.startTime.value;
      const endStr: string = this.eventForm.controls.endTime.value;
      const startM: any = moment().hour(parseInt(startStr.split(":")[0])).minutes(parseInt(startStr.split(":")[1]));
      let endM: any = moment().hour(parseInt(endStr.split(":")[0])).minutes(parseInt(endStr.split(":")[1]));
      if (startStr.split(":")[0] > endStr.split(":")[0]) {
        endM = moment().hour(parseInt(endStr.split(":")[0])).minutes(parseInt(endStr.split(":")[1])).add(1, "days");
      }
      return moment.duration(endM.diff(startM)).asMilliseconds();
    }
    return null;
  }

  maybeSetDuration(): boolean {
    const durationMillis: number = this.getCurrentDurationInMilliseconds();
    if (durationMillis) {
      this.durationStr = moment.utc(durationMillis).format(this.utilsService.getDurationFormat());
    } else {
      this.durationStr = '';
    }
    return true;
  }

  /* REPETITIONS */
  addRepetitions() {
    this.eventForm.controls["startDate"].markAsTouched();
    this.eventForm.controls["startTime"].markAsTouched();
    if (this.eventForm.controls["startDate"].value && this.eventForm.controls["startTime"].value) {
      let dialogRef = this.dialog.open(
        AddRecurrenceComponent, {
        width: '375px',
        data: { ...this.eventForm.value, duration: Math.round(this.getCurrentDurationInMilliseconds() / 60000) }
      });
      dialogRef.afterClosed().subscribe((eventRepetitions: EventRepetition[]) => {
        if (eventRepetitions) {
          //Update the repetitions
          this.eventRepetitions = this.mergeRepetitions(this.eventRepetitions, eventRepetitions);
        }
      });
    }
  }

  containsRepetition(rep, list) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].startDate.getTime() == rep.startDate.getTime() && list[i].startTime == rep.startTime) {
        return true;
      }
    }
    return false;
  }

  mergeRepetitions(origReps, newReps) {
    let origSize = origReps.length;
    for (let i = 0; i < newReps.length; i++) {
      if ((origSize + i) < this.maxRepetitions && !this.containsRepetition(newReps[i], origReps)) {
        origReps.push(newReps[i]);
      }
    }
    if (origSize < origReps.length) {
      this.snackBar.open((origReps.length - origSize) + " " + this.translate.instant("repetitions were added"), null, {
        duration: 4000,
      });
    }
    return origReps.sort((a, b) => { return a.startDate < b.startDate ? -1 : 1; });
  }

  removeRepetition(index: number) {
    this.eventRepetitions.splice(index, 1);
    if (this.errorRepetitionsVenue) {
      this.checkRepetitions();
    }
  }

  updateRepetition(index: number, newRep: EventRepetition) {
    this.eventRepetitions[index] = newRep;
    if (this.errorRepetitionsVenue) {
      this.checkRepetitions();
    }
  }

  checkRepetitions() {
    this.errorRepetitionsVenue = this.eventRepetitions.filter(rep => this.eventForm.value.mode !== 'online' && !rep.venue).length > 0;
    return !this.errorRepetitionsVenue;
  }


  /* IMAGES */
  onImageRemoved(index: number): void {
    this.images.removeAt(index);
    if (this.originalEvent?.id) {
      this.eventService.updateEvent(this.originalEvent.id, { images: this.eventForm.value.images });
    }
  }

  moveUp(index: number): void {
    if (index > 0) {
      const extras = this.images.value;
      const newExtras = this.swap(extras, index - 1, index);
      this.images.setValue(newExtras);
    }
  }

  moveDown(index: number) {
    const extras = this.images.value;
    if (index < extras.length - 1) {
      const newExtras = this.swap(extras, index, index + 1);
      this.images.setValue(newExtras);
    }
  }

  swap(arr: any[], index1: number, index2: number): any[] {
    arr = [...arr];
    const temp = arr[index1];
    arr[index1] = arr[index2];
    arr[index2] = temp;
    return arr;
  }

  uploadImage(): void {
    this.imageCompress.uploadFile()
      .then(({ image }) => {
        this.imageAsDataURL = image;
        this.images.push(this.fb.group({
          alt: new FormControl(''),
          caption: new FormControl(''),
          credits: new FormControl(''),
          originalSize: new FormControl('', null),
          largeSize: new FormControl('', null),
          smallSize: new FormControl('', null),
          urlLarge: new FormControl('', null),
          urlSmall: new FormControl('', null),
          urlOriginal: new FormControl('', null)
        }));
      })
      .catch((error) => {
        this.snackBar.open(this.translate.instant("There was an error while uploading the picture for your event. Please try again or contact us."), null, {
          duration: 4000,
        });
        console.error(error);
      });
  }

  importImageFromURL(imageURL: any) {
    if (imageURL.value) {
      const subImportImage = this.http.get(imageURL.value, { responseType: 'blob' }).pipe(
        catchError(_ => {
          this.snackBar.open(this.translate.instant("There was a problem importing the picture. Please upload it manually."), null, {
            duration: 4000,
          });
          return of(null);
        }
        )).subscribe(
          (blob) => {
            subImportImage.unsubscribe();
            if (blob) {
              var reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                this.imageAsDataURL = reader.result.toString();
                this.images.push(this.fb.group({
                  alt: new FormControl(''),
                  caption: new FormControl(''),
                  credits: new FormControl(''),
                  originalSize: new FormControl('', null),
                  largeSize: new FormControl('', null),
                  smallSize: new FormControl('', null),
                  urlLarge: new FormControl('', null),
                  urlSmall: new FormControl('', null),
                  urlOriginal: new FormControl('', null)
                }));
              }
            }
            imageURL.value = '';
          }
        );
    }
  }

  /* EVENT TYPE */
  onEventTypeChange($event: MatRadioChange) {
    this.hasSuper = $event.value == 'has-super';
    if (this.hasSuper) {
      this.eventForm.controls['super_event'].enable();
    } else {
      this.eventForm.controls['super_event'].disable();
    }
    this.isSuper = $event.value == 'super-event';
    if (this.isSuper) {
      this.eventForm.controls['endDate'].enable();
      this.eventForm.controls['startTime'].disable();
    } else {
      this.eventForm.controls['startTime'].enable();
      this.eventForm.controls['endDate'].disable();
    }
    this.eventForm.controls['endDate'].updateValueAndValidity();
    this.eventForm.controls['startTime'].updateValueAndValidity();
    this.eventForm.controls['super_event'].updateValueAndValidity();
  }

  /* AGE */
  onAgeRestrictionChange($event: MatRadioChange) {
    if ($event.value == 'minimum-age') {
      this.eventForm.controls['minimumAge'].enable();
    } else {
      this.eventForm.controls['minimumAge'].disable();
    }
    if ($event.value == 'maximum-age') {
      this.eventForm.controls['maximumAge'].enable();
    } else {
      this.eventForm.controls['maximumAge'].disable();
    }
    this.eventForm.controls['minimumAge'].updateValueAndValidity();
    this.eventForm.controls['maximumAge'].updateValueAndValidity();
  }

  /* PRICES */
  removePrice(i: number) {
    (<FormArray>this.eventForm.get('prices')).removeAt(i);
  }

  addPrice() {
    (<FormArray>this.eventForm.get('prices')).push(new FormGroup({
      type: new FormControl('', [Validators.required]),
      name_nb: new FormControl(),
      name_en: new FormControl(),
      price: new FormControl('', [Validators.min(0), Validators.required]),
    }));
  }

  initTicketTypes() {
    this.ticketTypeService.getAllTicketTypes().pipe(first()).subscribe(
      (allTT: TicketType[]) => {
        this.allTicketTypes = allTT;
      }
    );
  }

  addTicketType() {
    let dialogRef = this.dialog.open(
      AddTicketTypeComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.initTicketTypes();
    });
  }

  setTicketTypeNames(option, i) {
    const tt = this.allTicketTypes.find((ttt) => ttt.id == option.value);
    if (tt) {
      this.eventForm.get('prices')['controls'][i].get('name_nb').setValue(tt?.name_nb || '');
      this.eventForm.get('prices')['controls'][i].get('name_en').setValue(tt?.name_en || '');  
    }
  }

  evaluateIfAllTicketsAreFree(): void {
    this.allTicketsAreFree = true;
    for (let i = 0; i < this.eventForm.get('prices')['controls'].length; i++) {
      if (this.eventForm.get('prices')['controls'][i].get('price').value > 0) {
        this.allTicketsAreFree = false;
      }
    }
  }

  onPricesInfoChange(e) {
    if (!e.checked) {
      this.eventForm.controls['prices'].enable();
    } else {
      this.eventForm.controls['prices'].disable();
    }
    this.eventForm.controls['prices'].updateValueAndValidity();
  }

  /* SYNCHRONIZATIONS */
  initSynchronizationOptions() {
    environment.synchro.map((synchro: any) => {
      this.isValidForSynchronizing[synchro.destination] = true;
    });
  }

  initSynchroValidators() {
    this.eventForm.controls['title_nb'].valueChanges.pipe(debounceTime(1000)).subscribe(
      (newValue: string) => {
        if (newValue) {
          environment.synchro.map((synchro: any) => {
            const isAlredySynchronized = this.isEdit && this.originalEvent?.synchro && this.originalEvent?.synchro[synchro.type]
              && this.originalEvent.synchro[synchro.type][synchro.destination]
              && this.originalEvent.synchro[synchro.type][synchro.destination]['id'] || false;
            //Check if the title is unique also on the other HSK license, for those new events and events without that synchronization already done
            if (synchro.type == 'hsk' && (!this.isEdit || !isAlredySynchronized)) {
              const titleNb = this.eventForm.controls['title_nb'].value;
              const hskLicenseIsValid = this.eventService.existsEventWithSameTitle(titleNb, null, synchro.destination).subscribe(
                (existsEventInDestination: boolean) => {
                  hskLicenseIsValid.unsubscribe();
                  this.isValidForSynchronizing[synchro.destination] = !existsEventInDestination;
                }
              )
            }
          });
        }
      }
    );
    this.eventForm.controls['title_nb'].updateValueAndValidity();
  }

  isSynchronizable(destination: string): boolean {
    return environment.synchro?.indexOf(destination) != -1;
  }

  shallBeSynchronized(synchro: any): boolean {
    return (this.isEdit && this.originalEvent?.synchroDestinations?.indexOf(synchro.destination) != -1
      && this.isValidForSynchronizing[synchro.destination])
      || (!this.isEdit && this.isValidForSynchronizing[synchro.destination] && synchro.checked);
  }

  synchroLink(destination: string) {
    let dest = environment.synchro.filter(e => e.destination == destination);
    let eventExternalID = null;
    if (this.originalEvent && this.originalEvent.synchro) {
      //VisitNorway
      if (this.originalEvent?.synchro[destination]) {
        eventExternalID = this.originalEvent.synchro[destination].slug;
      }
      //Other HSK licenses
      if (this.originalEvent?.synchro?.hsk && this.originalEvent?.synchro?.hsk[destination]) {
        eventExternalID = this.originalEvent.synchro.hsk[destination].slug;
      }
      //Facebook pages
      if (this.originalEvent?.synchro?.facebook_page && this.originalEvent?.synchro?.facebook_page[destination]) {
        eventExternalID = this.originalEvent.synchro.facebook_page[destination].slug;
      }
    }
    if (dest.length == 1 && eventExternalID) {
      return dest[0].baseURL.replace("{slug}", eventExternalID);
    }
    return null;
  }

  changeSynchro(event) {
    //Set the selected synchronizations
    if (event.checked) {
      this.selectedSynchronizations.add(event.source.name);
    } else {
      this.selectedSynchronizations.delete(event.source.name);
    }
  }

  /* PUBLISHING OPTIONS */
  onPublishingOptionChange($event: MatRadioChange) {
    if ($event.value == 'now') {
      this.eventForm.controls['publishingDate'].disable();
    } else {
      this.eventForm.controls['publishingDate'].enable();
    }
    this.eventForm.controls['publishingDate'].updateValueAndValidity();
  }

  /* EDITABLE BY */
  initTeams() {
    this.teams$ = this.teamService.getTeamsForUserAsEventEditor();
  }

  createTeam() {
    let dialogRef = this.dialog.open(
      AddTeamComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.eventForm.controls["editableBy"].setValue(result);
    });
  }

  onCreateTeamSelected($event) {
    if ($event.isUserInput) {
      this.createTeam();
    }
  }

  /* CHECKING */
  checkCategories(): boolean {
    if (environment.preselectedCategories.length === 0) {
      this.maybeShowCategoryErrors();
      return this.selectedCategories.size > 0 && (environment.ui.showOnForm.maxCategories === -1 || this.selectedCategories.size <= environment.ui.showOnForm.maxCategories);
    }
    return true;
  }

  checkActivityTypes() {
    if (environment.ui.showOnForm.activityType) {
      this.maybeShowActivityTypeErrors();
      return this.selectedActivityTypes.size > 0 && this.selectedActivityTypes.size <= environment.ui.showOnForm.maxActivityType;
    }
    return true;
  }

  getVenueObject(venueID: string): Venue {
    return this.venues?.find(v => v.id == venueID) || null;
  }

  getOrganizerObject(orgID: string): Organizer {
    return this.organizers?.find(v => v.id == orgID) || null;
  }

  eventFromForm(): Event {
    //Gather all the form values
    let event: Event = {
      ... this.eventForm.value,
      //Set the repetitions
      repetitions: this.eventRepetitions,
      //Set the selected categories
      categories: environment.preselectedCategories.length === 0 ? Array.from(this.selectedCategories) : environment.preselectedCategories,
      //Set the selected tags
      tags: this.eventTags.slice(0, environment.ui.showOnForm.maxTags).join(","),
      //Set the selected tags
      activityTypes: Array.from(this.selectedActivityTypes).join(",") || '',
      //Set the poi
      mazeMapPoi: this.poi || null,
      //Set the update time to now
      updated_at: new Date(),
      //Cache status
      validCacheStatus: false
    };
    if (event.mode == 'offline') {
      //Be sure that every time that the event is updated, the last version of the venue is fetch
      event.venueObj = this.getVenueObject(this.eventForm.controls["venue"].value);
    }
    //Set end date
    if (!this.isSuper) {
      //Set start date with the time
      event.startDate = this.utilsService.buildStartDateWithTime(event.startDate, event.startTime).toDate();
      //Set the duration of the event based on the selected times
      event.duration = Math.round(this.getCurrentDurationInMilliseconds() / 60000);
      //End date based on the start date plus the duration
      event.endDate = moment(event.startDate).add(event.duration > 0 ? event.duration : 0, 'minutes').toDate();
    } else {
      event.startDate = moment(event.startDate).toDate();
      event.endDate = moment(event.endDate).toDate();
    }

    //Set the tickets opening date and time
    if (event.registrationEnabled) {
      event.ticketsFromDate = this.utilsService.buildStartDateWithTime(event.ticketsFromDate, event.ticketsFromTime).toDate();
    }
    //Synchronizations
    environment.synchro.map((synchro: any) => {
      if (!this.isValidForSynchronizing[synchro.destination]) {
        this.selectedSynchronizations.delete(synchro.destination);
      }
    });
    event.synchroDestinations = Array.from(this.selectedSynchronizations);

    //Other attributes
    if (this.isEdit) {
      event.id = this.originalEvent.id;
      event.event_slug = this.originalEvent.event_slug;
      if (this.originalEvent.synchro) {
        event.synchro = this.originalEvent.synchro;
      }
    }
    if (this.isEdit) {
      event.author_id = this.originalEvent.author_id;
    } else {
      event.author_id = this.userId;
      event.created_at = new Date();
    }
    //Publishing options
    if (event.publishingOption == 'now') {
      event.status = 'published';
    }
    if (event.publishingOption == 'scheduled') {
      event.status = 'scheduled';
      event.publishingDate = moment(event.publishingDate).hour(parseInt(event.publishingTime.split(":")[0])).minutes(parseInt(event.publishingTime.split(":")[1])).toDate();
    }
    if (event.publishingOption == 'unlisted') {
      event.status = 'unlisted';
    }

    return event;
  }

  async uploadEvent(event: Event) {
    this.eventListService.listenAndRefresh();
    const eventId = await this.eventService.addOrUpdateEvent(event);
    if (eventId) {
      const unsub = this.eventService.getEventFromFirestore(eventId, (storedEvent) => {
        if (storedEvent) {
          if (storedEvent.validCacheStatus) {
            unsub();
            this.authService.storeUserPreferences(storedEvent);
            this.loading = false;
            this.router.navigate(['/event/', storedEvent?.event_slug || event?.event_slug]);
            this.snackBar.open(this.translate.instant("Your event was saved successfully"), null, {
              duration: 4000,
            });
          }
        } else {
          this.snackBar.open(this.translate.instant("There was an error while saving your event"), null, {
            duration: 4000,
          });
          unsub();
          this.loading = false;
        }
      });
    }
  }

  checkValidity(): boolean {
    this.eventForm.markAllAsTouched();
    const validForm = this.eventForm.valid;
    const validCats = this.checkCategories();
    const validReps = this.checkRepetitions();
    const validActivityType = this.checkActivityTypes();
    return validForm && validCats && validReps && validActivityType;
  }

  deleteEvent(): void {
    const eventId: string = this.originalEvent.id;
    let dialogRef = this.dialog.open(
      QuestionWarningComponent, {
      width: '400px',
      data: {
        title: this.translate.instant("Delete event"),
        question: this.translate.instant("Are you sure that you want to remove this event and all its repetitions?")
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.eventService.deleteEvent(eventId)
          .then(
            () => {
              this.router.navigate(['/']);
              this.snackBar.open(this.translate.instant("Your event was successfully deleted."), null, {
                duration: 4000,
              });
            }
          )
          .catch(
            () => this.snackBar.open(this.translate.instant("There was a problem deleting your event. Please contact us."), null, {
              duration: 4000,
            })
          );
      }
    });

  }

  saveEvent() {
    if (this.checkValidity()) {
      //Starting the loading screen
      this.loading = true;
      const logs = this.originalEvent?.logs || [];
      const updatedEvent = this.eventFromForm();
      logs.push({
        author_id: this.currentUserInfo?.email || null,
        timestamp: new Date(),
        previous_version:  JSON.stringify(this.utilsService.fieldsInLog(this.originalEvent)),
        new_version:  JSON.stringify(this.utilsService.fieldsInLog(updatedEvent))
      });
      this.uploadEvent({logs, ...updatedEvent});
    } else {
      let nativeElement = this.eventFormContainer.nativeElement.querySelector('.mat-form-field.ng-invalid');
      if (nativeElement) {
        nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }

}
