import { Component, PLATFORM_ID, OnInit, Inject } from '@angular/core';

import { isPlatformBrowser } from '@angular/common';
import { LocalStorageService } from 'ngx-localstorage';

import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { UtilsService } from '../services/utils.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  consentAccepted: boolean = true;
  warningBrowser: boolean = true;
  environment: any;
  today: number = Date.now();
  currentVersion: string = null;
  showReloadWarning: boolean = false;

  constructor(
    private storageService: LocalStorageService,
    public sanitazer: DomSanitizer,
    private utilsService: UtilsService,
    public translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: any) {
    this.environment = environment;
    if (isPlatformBrowser(this.platformId)) {
      if (this.utilsService.storageAvailable()) {
        this.consentAccepted = this.storageService.get('consentAccepted', 'hvaskjerkalender') == 'true';
      }
      this.warningBrowser = /msie\s|trident/i.test(window.navigator.userAgent);
      //Listen to new app versions
      this.listenToNewAppVersions();
    }
  }

  ngOnInit() {
  }

  acceptConsent() {
    if (isPlatformBrowser(this.platformId)) {
      this.consentAccepted = true;
      if (this.utilsService.storageAvailable()) {
        this.storageService.set('consentAccepted', 'true', 'hvaskjerkalender');
      }
    }
  }

  reloadPage() {
    window.location.reload();
  }

  listenToNewAppVersions() {
    if (this.environment.ui.showOnForm.rsvp) {
      this.utilsService.getApp().subscribe(
        (appDocument: any) => {
          const lastVersion = appDocument?.lastVersion;
          if (this.currentVersion != null && lastVersion != this.currentVersion) {
            this.showReloadWarning = true;
          } else {
            this.currentVersion = lastVersion;
          }
        }
      );
    }
  }
}
