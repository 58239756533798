import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, } from '@angular/core';
import { Event } from '../models/event';
import { Observable, Subject, Subscription } from 'rxjs';
import { AbstractControl, AsyncValidatorFn, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { BookingService } from '../services/booking.service';
import { AuthService } from '../services/auth.service';
import { User } from 'firebase/auth';
import { first, map } from 'rxjs/operators';
import { UtilsService } from '../services/utils.service';
import { Price } from '../models/price';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss']
})
export class BookingFormComponent implements OnInit, OnDestroy {

  @Input()
  event: Event;

  //Auxiliary variables
  toucher: Subject<void> = new Subject<void>();
  addressCompulsory: boolean = false;
  goesToWaitingList: boolean = false;
  sub: Subscription;
  environment: any;
  show: string;

  //Event selector
  eventFormControl: FormControl = new FormControl(null, [Validators.required]);
  selectedInstance: Event;

  //Errors
  errors = {
    //Event selector
    error_0: true,
    //Name
    error_1: true,
    //Phone
    error_2: true,
    //Address
    error_3: true,
    //Comments
    error_4: false,
    //Tickets
    error_5: true
  };

  //Booking
  bookingForm: FormGroup = new FormGroup({
    actions: new FormArray([new FormGroup({
      authorEmail: new FormControl('guest'),
      name: new FormControl('guest-new-booking'),
      notifySMS: new FormControl(true),
      status: new FormControl('executing'),
      timestamp: new FormControl(new Date()),
      parameters: new FormArray([new FormControl()])
    })]),
    bookingNumber: new FormControl(this.utilsService.makeBookingId(5)),
    comments: new FormControl(),
    customerAddress: new FormControl(),
    customerId: new FormControl(),
    customerName: new FormControl(null, [Validators.required]),
    customerPhone: new FormControl(null),
    eventId: new FormControl(null, [Validators.required]),
    eventTitle: new FormControl(null, [Validators.required]),
    endDate: new FormControl(),
    //'invoice' or 'door'
    paymentMethod: new FormControl(),
    status: new FormControl(null, [Validators.required]),
    startDate: new FormControl(null, [Validators.required]),
    termsAndConditionsAccepted: new FormControl(true),
    tickets: new FormArray([]),
    venueName: new FormControl(null, [Validators.required])
  });


  @ViewChild("bookingFormEle")
  formContainer: ElementRef;

  @ViewChild("top")
  topMark: ElementRef;

  constructor(
    private authService: AuthService,
    private bookingService: BookingService,
    private utilsService: UtilsService
  ) {
    this.environment = environment;
  }

  ngOnInit(): void {
    this.initializeUserInfo();
    this.initEventInformation();
  }


  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  //INIT
  initializeUserInfo(): void {
    this.authService.getCurrentUserInfo().pipe(first()).subscribe(
      (userInfo: User) => {
        if (userInfo?.email) {
          this.bookingForm?.get(['actions', 0, 'authorEmail'])?.setValue(userInfo?.email);
        }
      }
    );
  }

  initEventInformation(): void {
    this.selectedInstance = this.event?.repetitions?.length == 0 ? this.event : null;
    this.addressCompulsory = this.event?.paymentMethod == 'invoice';
    this.errors['error_3'] = this.addressCompulsory;
    this.bookingForm?.get('eventId')?.setValue(this.event?.id);
    this.bookingForm?.get('eventTitle')?.setValue(this.event?.title_nb);
    this.bookingForm?.get('paymentMethod')?.setValue(this.event?.paymentMethod);
    if (this.event?.repetitions?.length == 0) {
      this.goesToWaitingList = this.event?.eventSoldOut;
      this.errors['error_0'] = false;
      this.bookingForm?.get('startDate')?.setValue(new Date(this.event?.startDate?.seconds * 1000));
      this.bookingForm?.get('endDate')?.setValue(new Date((this.event?.endDate?.seconds || this.event?.startDate?.seconds) * 1000));
      this.bookingForm?.get('venueName')?.setValue(this.event?.venue?.name || 'online');
      this.bookingForm.setControl('tickets', this.getTicketsControls(this.event?.prices));
    } else {
      this.initEventListener();
    }
  }

  getTicketsControls(prices: Price[]): AbstractControl {
    if (prices?.length > 0) {
      return new FormArray(prices.map(p => new FormGroup({
        numberTickets: new FormControl(),
        price: new FormControl(),
        type: new FormControl(),
        name_nb: new FormControl(),
        name_en: new FormControl()
      })));
    }
    return new FormArray([]);
  }

  initEventListener() {
    this.sub = this.eventFormControl.valueChanges.subscribe(
      (selectedInstance: Event) => {
        this.selectedInstance = selectedInstance || null;
        this.goesToWaitingList = this.selectedInstance?.eventSoldOut;
        this.errors['error_0'] = this.selectedInstance == null;
        this.bookingForm.get('endDate').setValue(selectedInstance?.endDate || null);
        this.bookingForm.get('startDate').setValue(selectedInstance?.startDate || null);
        this.bookingForm.get('venueName').setValue(selectedInstance?.venueObj?.name || null);
        this.bookingForm.setControl('tickets', this.getTicketsControls(this.event?.prices));
      });
  }


  onEventsLoaded(events: Event[]) {
    console.log(events);
    //In case there is only one option in the selector, set it as default
    if (events?.length == 1 && events[0]?.repetitions?.length == 0) {
      this.eventFormControl.setValue(events[0]);
    }
  } 


  //FORM LISTENERS
  onTicketsChange({ newTicketsValue, newStatus }) {
    this.goesToWaitingList = newStatus == 'waiting_list';
    this.bookingForm?.get('tickets')?.setValue(newTicketsValue);
    this.bookingForm?.get('status')?.setValue(newStatus);
  }

  onCustomerNameChange(newName) {
    this.bookingForm?.get('customerName')?.setValue(newName);
  }

  onCustomerPhoneChange(newPhone) {
    this.bookingForm?.get('customerPhone')?.setValue(newPhone);
  }

  onCustomerAddressChange(newAddress) {
    this.bookingForm?.get('customerAddress')?.setValue(newAddress);
  }

  onCustomerCommentsChange(newComments) {
    this.bookingForm?.get('comments')?.setValue(newComments);
  }


  validityOnComponent(isValid: boolean, index: number) {
    const errorName = 'error_' + index;
    this.errors[errorName] = !isValid;
    const formErrors = {};
    Object.keys(this.errors).forEach(e => { if (this.errors[e]) formErrors[e] = true; });
    if (Object.keys(formErrors).length == 0) {
      this.bookingForm.setErrors(null);
    } else {
      this.bookingForm.setErrors(formErrors);
    }
  }

  bookTickets() {
    //Touch all the fields to show the relevant validation errors
    this.toucher.next();
    console.log(this.bookingForm.errors);
    if (this.bookingForm.valid) {
      this.bookingService.addBooking(this.bookingForm.value);
      this.show = 'booking-success';
      let nativeElement = this.topMark.nativeElement;
      if (nativeElement) {
        nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } else {
      let nativeElement = this.formContainer.nativeElement.querySelector('.ng-invalid');
      if (nativeElement) {
        nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }

}
