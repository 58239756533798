import { Component, OnInit, Input, Output, EventEmitter, inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription, zip, of, Observable, BehaviorSubject } from 'rxjs';
import { NgxImageCompressService } from 'ngx-image-compress';
import { EventService } from '../services/event.service';
import { MatDialog } from '@angular/material/dialog';
import { QuestionWarningComponent } from '../question-warning/question-warning.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {

  //Loading
  uploading: boolean = false;
  deleting: boolean = false;
  errorOnLoading: boolean = false;

  //Percentage bars
  percentSmall: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  percentLarge: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  percentOriginal: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  //Image handling
  compressedImageSmall: string;
  compressedImageLarge: string;
  originalImage: string;

  //Subscriptions
  subImgUploading: Subscription;
  subImgRemoving: Subscription;

  @Input()
  imageForm: FormGroup;

  @Input()
  index: number;

  @Input()
  isLast: boolean;

  @Input()
  imageAsDataURL: string;

  @Output()
  onImageRemoved = new EventEmitter();

  @Output()
  onMoveUp = new EventEmitter();

  @Output()
  onMoveDown = new EventEmitter();

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
    private eventService: EventService,
    private imageCompress: NgxImageCompressService) {
  }


  ngOnInit(): void { 
  }

  ngAfterViewInit() {
    if (!this.imageForm.controls.urlLarge.value && this.imageAsDataURL) {
      //Prompt the user to pick the image
      this.uploadImage();
    }
  }

  ngOnDestroy() {
    if (this.subImgUploading) {
      this.subImgUploading.unsubscribe();
    }
    if (this.subImgRemoving) {
      this.subImgRemoving.unsubscribe();
    }
  }

  uploadToFirestore() {
    this.uploading = true;
    //Upload picture to server
    this.subImgUploading = zip(
      this.eventService.uploadImageToFirestore(this.compressedImageSmall, 'small', this.percentSmall),
      this.eventService.uploadImageToFirestore(this.compressedImageLarge, 'large', this.percentLarge),
      this.eventService.uploadImageToFirestore(this.originalImage, 'original', this.percentOriginal)
    ).subscribe(
      ([compressedSmallURL, compressedLargeURL, compressedOriginalURL]: any) => {
        this.imageForm.controls.urlSmall.setValue(compressedSmallURL || null);
        this.imageForm.controls.urlLarge.setValue(compressedLargeURL || null);
        this.imageForm.controls.urlOriginal.setValue(compressedOriginalURL || null);
        this.uploading = false;
      },
      (error) => {
        this.uploading = false;
        this.errorOnLoading = true;
        console.error(error);
      }
    );
  }

  /* IMAGE */
  //Compress image uploaded by the user to two different versions
  uploadImage() {
    this.imageForm.controls.originalSize.setValue(this.imageCompress.byteCount(this.imageAsDataURL));
    const i = new Image();
    this.originalImage = this.imageAsDataURL;
    i.onload = async () => {
      //Convert any image type to jpg
      URL.revokeObjectURL(i.src);             // free up memory
      const c = document.createElement("canvas"),  // create a temp. canvas
        ctx = c.getContext("2d");
      c.width = i.width;                      // set size = image, draw
      c.height = i.height;
      ctx.drawImage(i, 0, 0);

      // convert to File object, NOTE: we're using binary mime-type for the final Blob/File
      const jpeg = c.toDataURL("image/jpeg", 1);  // mime=JPEG, quality=1

      //Get the correct ratio for the desired sizes
      const ratioSmall = (220 / i.height) * 100;
      const ratioBig = (800 / i.height) * 100;

      //Compress the images
      await this.imageCompress.compressFile(jpeg, -2, ratioSmall, 100).then(
        result => {
          this.imageForm.controls.smallSize.setValue(this.imageCompress.byteCount(result));
          this.compressedImageSmall = result;
        }
      );
      await this.imageCompress.compressFile(jpeg, -2, ratioBig, 100).then(
        result => {
          this.imageForm.controls.largeSize.setValue(this.imageCompress.byteCount(result));
          this.compressedImageLarge = result;
        }
      );
      this.uploadToFirestore();
    };
    i.onerror = (err) => {
      this.errorOnLoading = true;
      console.error(err);
    };
    i.src = this.imageAsDataURL;
  }

  removeImage() {
    let dialogRef = this.dialog.open(
      QuestionWarningComponent, {
      width: '400px',
      data: {
        title: this.translate.instant("Delete"),
        question:this.translate.instant("The picture will be removed permanently. Do you want to continue?")
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        //Starting the loading screen
        this.deleting = true;
        //Upload picture to server
        this.subImgRemoving = zip(
          this.eventService.deleteImageByUrl(this.imageForm.controls.urlSmall.value),
          this.eventService.deleteImageByUrl(this.imageForm.controls.urlLarge.value),
          this.eventService.deleteImageByUrl(this.imageForm.controls.urlOriginal.value)
        ).subscribe(
          () => {
            this.onImageRemoved.emit();
          },
          (error) => {
            this.deleting = false;
            this.onImageRemoved.emit();
            console.error(error);
          }
        );
      }
    });
  }

  moveUp() {
    this.onMoveUp.emit();
  }

  moveDown() {
    this.onMoveDown.emit();
  }

}
