<div class="wrapper">
  <div class="admin mat-elevation-z1">
    <h1 class="mat-h1 margin-bottom" translate>Admin</h1>
    <div *ngIf="user?.roles?.admin">
      <div class="margin-top"></div>
      <div fxLayout="column">
        <h2 class="mat-h3 bold" translate>User account export and deletion</h2>
        <div fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start" class="full-width">
            <div fxFlex.gt-sm="grow" fxLayoutGap="10px" fxLayout="column" >
              <mat-form-field class="full-width">
                <input matInput [placeholder]="'User email' | translate" [formControl]="emailFormControl" required type="email">
                <mat-hint *ngIf="!userFound">{{'Introduce the user email' | translate}}</mat-hint>
                <mat-hint *ngIf="userFound">{{'User ID' | translate}}: {{userFound.uid}}</mat-hint>
                <mat-error *ngIf="emailFormControl.hasError('userNotFound')">{{'User not found' | translate }}</mat-error>
                <mat-error *ngIf="emailFormControl.hasError('email') || emailFormControl.hasError('required')">{{'You must introduce a valid email address' | translate }}</mat-error>
              </mat-form-field>
            </div>
            <div class="actions" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
              <button fxFlex.gt-sm="noshrink" fxFlex.lt-md="nogrow" mat-stroked-button (click)="exportData()">{{'Export data' | translate }}</button>
              <button fxFlex.gt-sm="noshrink" fxFlex.lt-md="nogrow" mat-stroked-button color="warn" (click)="deleteAccount()">{{'Delete account' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="margin-top"></div>
      <mat-divider></mat-divider>
      <div *ngIf="hasFacebookSynchronizations">
        <div fxLayout="column">
          <h2 class="mat-h3 bold" translate>Facebook settings</h2>
          <div fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="10px" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start" class="full-width">
              <div fxFlex.gt-sm="grow" fxLayoutGap="10px">
                <div fxFlex.gt-sm="grow">{{'Facebook token' | translate }}</div>
              </div>
              <div class="actions" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
                <button mat-stroked-button type="button" (click)="fbLogin()">{{'Renew facebook token' | translate}}</button>
                <button mat-stroked-button type="button" color="warn" (click)="fbLogout()">{{'Logout from facebook' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="margin-top"></div>
        <mat-divider></mat-divider>
      </div>

    </div>
    <div *ngIf="!user?.roles?.admin" translate>
      You need to have admin privileges to access this page
    </div>
  </div>
</div>
