import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Event } from '../models/event';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../services/utils.service';
import { FavoritesService } from '../services/favorites.service';

@Component({
  selector: 'app-event-tile',
  templateUrl: './event-tile.component.html',
  styleUrls: ['./event-tile.component.scss']
})
export class EventTileComponent implements OnInit {

  @Input()
  event: Event;

  @Output()
  afterViewInit = new EventEmitter();

  isFavorite: boolean = false;
  
  constructor(private translate: TranslateService,
              private favoriteService: FavoritesService,
              private utilsService: UtilsService) { }

  ngOnInit(): void {
    this.isFavorite = this.favoriteService.isFavorite(this.event.event_slug);
  }
  
  ngAfterViewInit() {
    this.afterViewInit.emit();
  }


  getDate(date): string {
    const gDate = this.utilsService.transformToDate(date);
    if (this.translate.currentLang == 'nb') {
      if (this.event.type == 'super-event') {
        return formatDate(gDate, "d. MMMM yyyy", 'nb-NO');
      } else {
        return formatDate(gDate, "d. MMMM yyyy, HH:mm", 'nb-NO');
      }
    } else {
      if (this.event.type == 'super-event') {
        return formatDate(gDate, "d MMMM yyyy", 'nb-NO');
      } else {
        return formatDate(gDate, "d MMMM yyyy, HH:mm", 'nb-NO');
      }
    }
  }

  getMonth(date) {
    const gDate = this.utilsService.transformToDate(date);
    if (this.translate.currentLang == 'nb') {
      return formatDate(gDate, "MMM", 'nb-NO');
    } else {
      return formatDate(gDate, "MMM", 'en');
    }
  }

  getDay(date) {
    const gDate = this.utilsService.transformToDate(date);
    return formatDate(gDate, "d", 'nb-NO');
  }

  maybeGetUntilDate() {
    const gStartDate = this.utilsService.transformToDate(this.event.startDate);
    const gEndDate = this.utilsService.transformToDate(this.event.endDate);
    if (formatDate(gStartDate, "dMMM", 'nb-NO') != formatDate(gStartDate, "dMMM", 'nb-NO')) {
      if (this.translate.currentLang == 'nb') {
        return 'til ' + formatDate(gEndDate, "MMM d", 'nb-NO');
      } else {
        return 'to ' + formatDate(gEndDate, "MMM d", 'en');
      }
    }  
  }

  toggleFavorite(eventSlug: string) {
    this.favoriteService.toggle(eventSlug);
    this.isFavorite = this.favoriteService.isFavorite(eventSlug);
  }
}
