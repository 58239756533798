import { Component, OnInit, PLATFORM_ID, Inject, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-localstorage';
import { AuthService } from './services/auth.service';
import { RollbarService } from './modules/rollbar';
import Rollbar from 'rollbar';

import { User } from './models/user';
import defaultLanguage from "./../assets/i18n/nb.json";

import { environment } from '../environments/environment';

import moment from 'moment';
import { UtilsService } from './services/utils.service';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  environment: any;
  userInfoSubs: any;
  user: User;
  user$: Observable<User | boolean>;
  loading: boolean = true;
  isMobile: boolean;
  opened: boolean = false;

  subsBreakpoint: Subscription;

  @ViewChild('drawer') drawer: any;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public translate: TranslateService,
    private titleService: Title,
    private route: ActivatedRoute,
    public authService: AuthService,
    @Inject(RollbarService) private rollbar: Rollbar,
    @Inject(PLATFORM_ID) private platformId: any,
    private storageService: LocalStorageService,
    private utilsService: UtilsService,
  ) {
    //Set default language
    this.setDefaultLang();
    this.environment = environment;
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      //Setup breakpoint observer
      this.setupBreakpointObserver();
      //Set the proper language based on the UX
      this.setProperLanguage();
      this.user$ = this.authService.getCurrentUserInfo();
      //WORKAROUND FOR CRASH DROPDOWN IN CHROME
      document.addEventListener('DOMNodeInserted', function () {
        const elements = document.querySelectorAll('[aria-owns]');
  
        elements.forEach(element => {
          element.removeAttribute('aria-owns');
        });
      });
    }
  }

  ngAfterViewInit() {
    //Setup user info for rollbar
    this.authService.onAuthStateChanged(authChange => this.setupUserInfo(authChange));
  }

  ngOnDestroy() {
    this.subsBreakpoint?.unsubscribe();
  }
  

  setupBreakpointObserver() {
    //Observe the breakpoint
    this.subsBreakpoint = this.breakpointObserver
      .observe([
        Breakpoints.Handset,
        Breakpoints.Tablet
      ])
      .pipe(map((result: BreakpointState) => result.matches)).subscribe(
        (isMobile: boolean) => {
          this.isMobile = isMobile;
        }
      );
  }

  setupUserInfo(authChange): void {
    if (authChange?.uid) {
      this.userInfoSubs = this.authService.getCurrentUserInfo().subscribe(
        (user: User) => {
          this.loading = false;
          if (user) {
            this.opened = !this.isMobile && this.environment.theme != 'trdevents';
            this.user = user;
            this.rollbar.configure({
              payload: {
                person: {
                  id: user.uid,
                  email: user.email
                }
              }
            });
          } else {
            this.user = null;
          }
        }
      );
    }
  }

  setDefaultLang() {
    this.translate.setTranslation('nb', defaultLanguage);
    this.translate.setDefaultLang('nb');
    this.titleService.setTitle(environment.content.siteNameTranslatable['nb']);
    this.setMomentLocale('nb');
  }

  setProperLanguage() {
    this.route.queryParams.subscribe(params => {
      let useLan = this.selectBestLanguageChoice(params);
      this.translate.use(useLan);
      this.authService.changeLanguage(useLan);
      this.titleService.setTitle(environment.content.siteNameTranslatable[useLan]);
      this.setMomentLocale(useLan);
    });
  }

  selectBestLanguageChoice(params: any) {
    if (!environment.language.multilanguage) {
      return environment.language.defaultLanguage;
    }
    let useLan = 'nb';
    if (params['lan'] && (params['lan'] == 'nb' || params['lan'] == 'en')) {
      //Set via query param?
      useLan = params['lan'];
    } else {
      //Stored by the user first?
      if (isPlatformBrowser(this.platformId)) {
        if (this.utilsService.storageAvailable()) {
          let storedLanguage = this.storageService.get('lan', 'hvaskjerkalender');
          if (storedLanguage) {
            useLan = storedLanguage;
          }
        }
      }
    }
    return useLan;
  }

  setMomentLocale(language: string) {
    if (language != 'nb') {
      moment.locale('en-GB');
    } else {
      moment.locale(language);
    }
  }

  closeDrawerOnMobile() {
    if (this.isMobile) {
      this.drawer.close();
    }
  }
}
