<div class="more" fxLayout="row" fxLayoutAlign="start start">
    <div fxHide.lt-md fxFlex="300px" fxLayout="column" fxLayoutAlign="start center">
        <div fxLayout="column" fxLayoutAlign="start start">
            <div class="title">{{'Organizers' | translate}}</div>
            <a routerLinkActive="active-link" *ngIf="user" [routerLink]="[{outlets: {organizers: 'my-events'}}]">{{'My events' |
                translate}}</a>
            <a routerLinkActive="active-link" *ngIf="user" [routerLink]="[{outlets: {organizers: 'add-an-event'}}]">{{'Add an event' |
                translate}}</a>
            <a routerLinkActive="active-link" *ngIf="user" [routerLink]="[{outlets: {organizers: 'my-teams'}}]">{{'My teams' |
                translate}}</a>
            <a routerLinkActive="active-link" *ngIf="!user" [routerLink]="[{outlets: {organizers: 'login'}}]">{{'Login organizers' |
                translate}}</a>
            <a routerLinkActive="active-link" [routerLink]="[{outlets: {organizers: 'why'}}]">{{'Why use' |
                translate}} {{environment.content.siteName}}</a>
            <a routerLinkActive="active-link" [routerLink]="[{outlets: {organizers: 'advertising'}}]">{{'Advertising' |
                translate}}</a>
            <a routerLinkActive="active-link" [routerLink]="[{outlets: {organizers: 'what'}}]">{{'What is' |
                translate}} {{environment.content.siteName}}</a>
            <a routerLinkActive="active-link" [routerLink]="[{outlets: {organizers: 'privacy-policy'}}]">{{'Privacy Policy' |
                translate}}</a>
            <a routerLinkActive="active-link" [routerLink]="[{outlets: {organizers: 'about-us'}}]">{{'About us' |
                translate}}</a>
            <a routerLinkActive="active-link" *ngIf="user" (click)="logOut()">{{'Log out' |
                translate}}</a>
        </div>
    </div>
    <div class="full-width">
        <router-outlet name='organizers'></router-outlet>
    </div>
</div>