import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { environment } from '../../environments/environment';


@Injectable()
export class SeoService {

  constructor(private meta: Meta) { }

  generateTags(config) {
    // default values
    config = {
      title: environment.content.siteName,
      description: environment.ui.showWelcomeMessage ? environment.content.welcomeMessage.nb : environment.content.siteName,
      image: environment.siteURL + '/assets/pictures/' + environment.placeholderImage,
      imageWidth: 900,
      imageHeight: 200,
      slug: '',
      fbAppID: environment.synchroFacebookAppId,
      admins: 'pablo.margareto',
      ...config
    }
    //Google
    this.meta.updateTag({ property: 'description', content: config.description });

    //Twitter
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: environment.content.siteName });
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({ name: 'twitter:description', content: config.description });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    //Facebook
    this.meta.updateTag({ property: 'og:type', content: "website" });
    this.meta.updateTag({ property: 'fb:app_id', content: config.fbAppID });
    this.meta.updateTag({ property: 'fb:admins', content: config.admins });
    this.meta.updateTag({ property: 'og:site_name', content: environment.content.siteName });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:image:alt', content: config.imageAlt });
    this.meta.updateTag({ property: 'og:image:width', content: config.imageWidth });
    this.meta.updateTag({ property: 'og:image:height', content: config.imageHeight });
    this.meta.updateTag({ property: 'og:url', content: environment.siteURL + `/${config.slug}` });
  }

}
