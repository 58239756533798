import { Injectable, inject } from '@angular/core';

import { Observable, Observer, from } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { Event } from '../models/event';
import { SelectableEvent } from '../models/selectable-event';

import moment from 'moment-timezone';
import { environment } from '../../environments/environment';
import { formatDate, formatCurrency } from '@angular/common';
import { Booking, Ticket } from '../models/booking';
import { BookingStatusService } from './booking-status.service';
import { TicketTypeService } from './ticket-type.service';
import { PhonePipe } from '../pipes/phone';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable()
export class UtilsService {

  environment: any;

  private firestore: Firestore = inject(Firestore);
  private functions: Functions = inject(Functions);

  constructor(
    private bookingStatusService: BookingStatusService,
    private ticketTypeService: TicketTypeService,
    private translate: TranslateService) {
    this.environment = environment;
    moment.tz.setDefault("Europe/Oslo");
  }

  slugify(text, separator) {
    text = text.toString().toLowerCase().trim();

    const sets = [
      { to: 'a', from: '[ÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
      { to: 'c', from: '[ÇĆĈČ]' },
      { to: 'd', from: '[ÐĎĐÞ]' },
      { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
      { to: 'g', from: '[ĜĞĢǴ]' },
      { to: 'h', from: '[ĤḦ]' },
      { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
      { to: 'j', from: '[Ĵ]' },
      { to: 'ij', from: '[Ĳ]' },
      { to: 'k', from: '[Ķ]' },
      { to: 'l', from: '[ĹĻĽŁ]' },
      { to: 'm', from: '[Ḿ]' },
      { to: 'n', from: '[ÑŃŅŇ]' },
      { to: 'o', from: '[ÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
      { to: 'oe', from: '[Œ]' },
      { to: 'p', from: '[ṕ]' },
      { to: 'r', from: '[ŔŖŘ]' },
      { to: 's', from: '[ßŚŜŞŠ]' },
      { to: 't', from: '[ŢŤ]' },
      { to: 'u', from: '[ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
      { to: 'w', from: '[ẂŴẀẄ]' },
      { to: 'x', from: '[ẍ]' },
      { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
      { to: 'z', from: '[ŹŻŽ]' },
      { to: '-', from: '[·/_,:;\']' }
    ];

    sets.forEach(set => {
      text = text.replace(new RegExp(set.from, 'gi'), set.to);
    });

    text = text.toString().toLowerCase()
      .replace(/\s+/g, '-')         // Replace spaces with -
      .replace(/&/g, '-and-')       // Replace & with 'and'
      .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
      .replace(/\--+/g, '-')        // Replace multiple - with single -
      .replace(/^-+/, '')           // Trim - from start of text
      .replace(/-+$/, '');          // Trim - from end of text

    if ((typeof separator !== 'undefined') && (separator !== '-')) {
      text = text.replace(/-/g, separator);
    }

    return text;
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  makeBookingId(length): string {
    let result = '';
    const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getUniqueSlug(eventTitle, eventSameSlug) {
    let proposedSlug = this.slugify(eventTitle, '-');
    if (eventSameSlug != null) {
      proposedSlug += '-' + this.makeid(5);
    }
    return proposedSlug;
  }

  getAllHours(): SelectableEvent[] {
    let hours: SelectableEvent[] = [];

    for (let i: number = 0; i < 24; i++) {
      hours.push({ value: i + ':00', viewValue: i + ':00' });
      hours.push({ value: i + ':15', viewValue: i + ':15' });
      hours.push({ value: i + ':30', viewValue: i + ':30' });
      hours.push({ value: i + ':45', viewValue: i + ':45' });
    }
    return hours;
  }

  getAllDurations() {
    let durations: SelectableEvent[] = [];

    for (let i: number = 0; i < 96; i++) {
      durations.push({ value: (i * 15), viewValue: this.getDurationFromMins(i * 15) });
    }
    return durations;
  }

  getStartDate(event: Event) {
    let start = moment(event.startDate.seconds * 1000);
    if (this.translate.currentLang == 'nb') {
      return start.format("MMM Do HH.mm");
    } else {
      return start.format("MMM Do hh:mm A");
    }
  }

  getUpdatedAt(event: Event) {
    let updated_at = moment(event.updated_at.seconds * 1000);
    if (this.translate.currentLang == 'nb') {
      return updated_at.format("MMM Do HH.mm");
    } else {
      return updated_at.format("MMM Do hh:mm A");
    }
  }

  getCompactDateFormat() {
    if (this.translate.currentLang == 'nb') {
      return "DD.MM.YYYY";
    } else {
      return "DD/MM/YYYY";
    }
  }

  getDateFormat(withWeekDay?: boolean) {
    if (this.translate.currentLang == 'nb') {
      return (withWeekDay ? 'ddd ' : '') + "Do MMM";
    } else {
      return (withWeekDay ? 'ddd ' : '') + "MMM Do";
    }
  }

  getDateFormatForSingle(): string {
    if (this.translate.currentLang == 'nb') {
      return "dddd[,] Do MMMM[,] YYYY";
    } else {
      return "dddd[,] MMMM D[,] YYYY";
    }
  }

  getDateFormatForRepetition(): string {
    if (this.translate.currentLang == 'nb') {
      return "Do MMMM[,] YYYY - [kl] HH.mm";
    } else {
      return "MMMM D[,] YYYY - h:mm A";
    }
  }

  getCompactDateTimeFormat() {
    if (this.translate.currentLang == 'nb') {
      return "HH.mm DD.MM.YY";
    } else {
      return "h:mm A DD/MM/YY";
    }
  }

  getTimeFormat(): string {
    if (this.translate.currentLang == 'nb') {
      return "[kl] HH.mm";
    } else {
      return "h:mm A";
    }
  }

  getDurationFormat(): string {
    if (this.translate.currentLang == 'nb') {
      return "H[t] mm[m]";
    } else {
      return "H[h] mm[m]";
    }
  }

  getTimeFormatForSingle() {
    if (this.translate.currentLang == 'nb') {
      return "[kl] HH.mm";
    } else {
      return "h:mm A";
    }
  }

  getTitle(event: Event): string {
    if (event) {
      if (this.translate.currentLang == 'en' && event.title_en) {
        return event.title_en;
      }
      return event.title_nb;
    }
    return "";
  }

  getTitleFrontpage(): string {
    if (this.translate.currentLang == 'en') {
      return this.environment.content.titleFrontpage.en;
    }
    return this.environment.content.titleFrontpage.nb;
  }

  buildStartDateWithTime(startDate, startTime) {
    //Set start date with the time
    let hour = +startTime.split(':')[0];
    let minutes = +startTime.split(':')[1];
    let startDateWithTime = moment(startDate);
    startDateWithTime.hour(hour);
    startDateWithTime.minute(minutes);
    return startDateWithTime;
  }

  getSummary(event: Event): string {
    if (event) {
      if (this.translate.currentLang == 'en' && event.summary_en) {
        return this.getHtmlFromPlainText(event.summary_en);
      }
      if (event.summary_nb) {
        return this.getHtmlFromPlainText(event.summary_nb);
      }
    }
    return "";
  }

  getDesc(event: Event): string {
    if (event) {
      if (this.translate.currentLang == 'en' && event.desc_en) {
        return this.getHtmlFromPlainText(event.desc_en);
      }
      if (event.desc_nb) {
        return this.getHtmlFromPlainText(event.desc_nb);
      }
    }
    return "";
  }

  getEventSchema(event: Event, superEvent?: Event, subEvents?: Event[]): {} {
    let eventSchema = {
      "@context": "https://schema.org",
      "@type": "Event",
      "name": this.getTitle(event),
      "startDate": moment(event.startDate.seconds * 1000).format(),
      "endDate": moment(event.endDate.seconds * 1000).format(),
      "image": (event.images.length > 0 ? event.images.map(image => image.urlLarge) : []),
      "description": this.getDesc(event),
      "eventAttendanceMode": event.mode == "online" ? "https://schema.org/OnlineEventAttendanceMode" : "https://schema.org/OnlineEventAttendanceMode",
      "eventStatus": event.eventCancelled ? "https://schema.org/EventCancelled" : "https://schema.org/EventScheduled",
    };
    if (event.mode == "offline" && event.venue) {
      eventSchema["location"] = {
        "@type": "Place",
        "name": event.venue.name,
        "address": {
          "@type": "PostalAddress",
          "streetAddress": this.venueAddress(event.venue.address),
          "addressLocality": this.venueLocation(event.venue.address),
          "addressCountry": "NO"
        }
      }
    }
    if (event.mode == "online") {
      eventSchema["location"] = {
        "@type": "VirtualLocation",
        "url": event.streamingURL || event.moreInfoURL || this.environment.siteURL + '/event/' + event.event_slug,
      }
    }
    if (event.organizers && event.organizers.length > 0 && event.organizers[0].organizerObj) {
      eventSchema["organizer"] = {
        "@type": "Organization",
        "name": event.organizers[0].organizerObj.name,
        "url": event.organizers[0].organizerObj.website || this.environment.siteURL + '/organizer/' + event.organizers[0].organizerObj.slug,
        "telephone": event.organizers[0].organizerObj.telephoneNumber || '',
        "email": event.organizers[0].organizerObj.email || ''
      }
    }
    if (event.maximumAge) {
      eventSchema["typicalAgeRange"] = event.maximumAge + "-";
    }
    if (event.minimumAge) {
      eventSchema["typicalAgeRange"] = event.minimumAge + "+";
    }
    let offers = this.getOfferForSchema(event);
    if (offers) {
      eventSchema["offers"] = offers;
    }
    if (superEvent) {
      eventSchema["superEvent"] = this.getEventSchema(superEvent);
    }
    if (subEvents) {
      eventSchema["subEvents"] = subEvents.map(event => this.getEventSchema(event));
    }
    return eventSchema;
  }

  getOfferForSchema(event: Event): any {
    if (!event.noTicketsInfo) {
      return {
        "@type": "Offer",
        "url": event.ticketsURL ? event.ticketsURL : "",
        "price": event.prices?.find((price) => price.type == 'REGULAR')?.price || event.regularPrice,
        "priceCurrency": "NOK",
        "availability": event.eventSoldOut ? "https://schema.org/SoldOut" : "https://schema.org/InStock"
      };
    }
    return undefined;
  }

  //TODO Change for the right ones, when the addresses be formatted
  venueAddress(venueAddress: string): string {
    if (venueAddress != null) {
      return venueAddress.replace(", Norway", "").replace(", Norge", "").split(",")[0];
    }
    return '';
  }

  venueLocation(venueAddress: string): string {
    if (venueAddress != null) {
      return venueAddress.replace(", Norway", "").replace(", Norge", "").split(",")[1];
    }
    return '';
  }

  trim(tooLong: string): string {
    if (tooLong.length > 50) {
      return tooLong.substr(0, 48) + "...";
    }
    return tooLong;
  }

  trimWithMax(tooLong: string, maxNumber: number): string {
    if (tooLong.length > maxNumber) {
      return tooLong.substr(0, maxNumber - 2) + "...";
    }
    return tooLong;
  }

  pad(number): string {
    let s = number + '';
    while (s.length < 2) { s = "0" + s; }
    return s;
  }

  getLocalizedHour(hourString): string {
    let hour = moment(hourString, 'H:mm');
    return hour.format(this.getTimeFormat());
  }

  getDurationFromMins(mins): string {
    let h = mins / 60 | 0,
      m = mins % 60 | 0;
    let hFormat = "H [hours]";
    let mFormat = "m [minutes]";
    let andFormat = " [and] ";

    if (this.translate.currentLang == 'nb') {
      hFormat = "H [timer]";
      mFormat = "m [minuter]";
      andFormat = " [og] ";
    }
    let minutes = moment.utc().hours(h).minutes(m).format('m');

    if (mins == 0 && this.translate.currentLang == 'nb') {
      return 'Ingen';
    }
    if (mins == 0 && this.translate.currentLang != 'nb') {
      return 'None';
    }
    if (mins < 60) {
      return moment.utc().hours(h).minutes(m).format(mFormat);
    }
    if (minutes == '0') {
      return moment.utc().hours(h).minutes(m).format(hFormat);
    }
    return moment.utc().hours(h).minutes(m).format(hFormat + andFormat + mFormat);
  }

  getHtmlFromPlainText(text: string): string {
    if (text.indexOf("<p>") === -1) {
      let htmlText = text.replace(/(\r\n|\n|\r)/g, "<br />");

      //URLs starting with http://, https://, or ftp://
      let replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
      htmlText = htmlText.replace(replacePattern1, '<a class="inner-link" href="$1" target="_blank">$1</a>');

      //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
      let replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
      htmlText = htmlText.replace(replacePattern2, '$1<a class="inner-link" href="http://$2" target="_blank">$2</a>');

      //Change email addresses to mailto:: links.
      let replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
      htmlText = htmlText.replace(replacePattern3, '<a class="inner-link" href="mailto:$1">$1</a>');

      return htmlText;
    }
    return text;
  }

  getPlainTextFromHtml(text: string): string {
    return text?.replace(/<.*?>/g, '') || '';
  }


  similarity(s1, s2) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength == 0) {
      return 1.0;
    }
    return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength);
  }

  editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i == 0)
          costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) != s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue),
                costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0)
        costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }

  maybeToDate(date): Date {
    if (date instanceof Date) {
      return date;
    }
    return date.toDate();
  }

  transformToDate(date: any): Date {
    if (date?.seconds) {
      return new Date(date.seconds * 1000);
    }
    if (date instanceof Date) {
      return date;
    }
    if (date?.length > 0) {
      return moment(date, "YYYY-MM-DD HH:mm:ssZ").toDate();
    }
    return date.toDate();
  }


  getBase64ImageFromURL(url: string): Observable<string> {
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    // We create a HTML canvas object that will create a 2d image
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  //Returns an array with elements without duplicated provided key
  uniqueBy(a, key) {
    let seen = new Set();
    return a.filter(item => {
      if (item) {
        let k = item[key];
        return seen.has(k) ? false : seen.add(k);
      }
      return false;
    });
  }

  flattenArray(arr) {
    return arr.reduce((flat, toFlatten) => {
      return flat.concat(Array.isArray(toFlatten) ? this.flattenArray(toFlatten) : toFlatten);
    }, []);
  }

  storageAvailable() {
    try {
      var storage = window['localStorage'],
        x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    }
    catch (e) {
      return e instanceof DOMException && (
        // everything except Firefox
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage && storage.length !== 0;
    }
  }

  getApp() {
    return from(getDoc(doc(this.firestore, 'settings', 'app')).then((snap) => ({ id: snap.id, ...snap.data() })));
  }

  getNumTickets(booking: Booking): number {
    return booking.tickets.reduce((c, p) => (c + p.numberTickets), 0);
  }

  getStatus(status: string): string {
    return this.bookingStatusService.getBookingStatusName(status);
  }

  getTicketType(ticket: Ticket): string {
    return this.ticketTypeService.getTicketTypeName(ticket);
  }

  getLastNameAndName(fullName: string) {
    const splitted = fullName?.trim().split(" ") || '';
    if (splitted.length > 1) {
      return splitted[splitted.length - 1] + ", " + fullName.trim().replace(splitted[splitted.length - 1], "");
    }
    if (splitted.length > 0) {
      return splitted[0];
    }
    return '';
  }

  getAuthor(email: string): string {
    let author = this.translate.instant('Unknown');
    if (email) {
      if (email == 'guest') {
        author = this.translate.instant('Guest');
      } else {
        author = email.split("@")[0];
      }
    }
    return author;
  }

  //Save as CSV
  saveAsCSV(bookings: Booking[], event: any) {
    if (bookings?.length > 0 && event) {
      const eventDate = formatDate(new Date(event.startDate?.seconds * 1000 || 0), "short", this.translate.currentLang == 'nb' ? 'nb-NO' : 'en-UK');
      const eventInfo = ['"' + event.title_nb + "\n" + (event.venueObj?.name || 'Online') + "\n" + eventDate + "\n"
        + "(" + bookings.reduce((pv, cv) => cv.tickets.reduce((ppv, ccv) => ccv.numberTickets + ppv, 0) + pv, 0) + " / " + event.availableTickets + ")" + '"', '', '', '', '', ''];
      const header = [this.translate.instant('Checker'), this.translate.instant('Name'), this.translate.instant('Tickets'), this.translate.instant('Total amount'), this.translate.instant('Telephone number'), this.translate.instant('Comments')];
      if (event.paymentMethod == 'invoice') {
        header.push(this.translate.instant('Address'));
      }
      const csv = bookings.map((b) => {
        const row = [
          "| " + [...Array(b.tickets.reduce((p, c) => c.numberTickets + p, 0)).keys()].map(() => "_").join(" | ") + " |",
          '"' + this.getLastNameAndName(b.customerName) + '"',
          b.tickets.map((ticket) => ticket.numberTickets > 0 ? (ticket.numberTickets + 'x ' + this.getTicketType(ticket)) : '').join(" "),
          '"' + formatCurrency(b.tickets.reduce((p, c) => (c.numberTickets * c.price) + p, 0), (this.translate.currentLang == 'nb' ? 'nb-NO' : 'en-UK'), 'kr', 'NOK', '0.2-2') + '"',
          (new PhonePipe()).transform(b.customerPhone) || 'N/A',
          (b.comments && '"' + b.comments + '"') || ''];
        if (event.paymentMethod == 'invoice') {
          row.push((b.customerAddress?.formattedAddress ? '"' + b.customerAddress?.formattedAddress + '"' : ''));
        }
        return row.join(',');
      });

      csv.unshift(header.join(','));
      csv.unshift(eventInfo.join(','));
      const csvArray = csv.join('\r\n');

      const a = document.createElement('a');
      const blob = new Blob([csvArray], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const fileName = event.title_nb + " - " + eventDate + " - " + " in " + event.venueObj?.name || 'Online';
      a.href = url;
      a.download = fileName + '.csv';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }

  //Save as PDF
  saveAsPDF(bookings: Booking[], event: any) {
    if (bookings?.length > 0 && event) {
      const eventDate = formatDate(new Date(event.startDate?.seconds * 1000 || 0), "short", this.translate.currentLang == 'nb' ? 'nb-NO' : 'en-UK');
      var doc = new jsPDF();
      doc.text(event.title_nb + "\n" + (event.venueObj?.name || 'Online') + "\n" + eventDate + "\n"
        + "(" + bookings.reduce((pv, cv) => cv.tickets.reduce((ppv, ccv) => ccv.numberTickets + ppv, 0) + pv, 0) + " / " + event.availableTickets + ")", 10, 10);
      const head = [
        [this.translate.instant('Checker'),
        this.translate.instant('Name'),
        this.translate.instant('Tickets'),
        this.translate.instant('Total amount'),
        this.translate.instant('Telephone number'),
        this.translate.instant('Comments')
        ]];
      if (event.paymentMethod == 'invoice') {
        head[0].push(this.translate.instant('Address'));
      }
      // Or use javascript directly:
      autoTable(doc, {
        head,
        body: bookings.map((b) => {
          const body = [
            "| " + [...Array(b.tickets.reduce((p, c) => c.numberTickets + p, 0)).keys()].map(() => "_").join(" | ") + " |",
            this.getLastNameAndName(b.customerName),
            { content: b.tickets.map((ticket) => ticket.numberTickets > 0 ? (ticket.numberTickets + 'x ' + this.getTicketType(ticket)) : '').join("\n"), styles: { cellWidth: 30 } },
            { content: formatCurrency(b.tickets.reduce((p, c) => (c.numberTickets * c.price) + p, 0), (this.translate.currentLang == 'nb' ? 'nb-NO' : 'en-UK'), 'kr', 'NOK', '0.2-2'), styles: { cellWidth: 30 } },
            (new PhonePipe()).transform(b.customerPhone) || 'N/A',
            b.comments || ''];
          if (event.paymentMethod == 'invoice') {
            body.push(b.customerAddress?.formattedAddress || '');
          }
          return body;
        }),
        startY: 40,
        rowPageBreak: 'avoid',
        didDrawPage: (data) => {
          doc.text(data.pageNumber.toString(), 100, doc.internal.pageSize.height - 5);
        },
      });
      const filename = event.title_nb + " - " + eventDate + " - " + " in " + event.venueObj?.name || 'Online';
      doc.save(filename + '.pdf')
    }
  }

  contact(contactInfo) {
    const callable = httpsCallable(this.functions, 'contact');
    return callable(contactInfo);
  }

  fieldsInLog(inEvent: Event): any {
    const fields = [
      "availableTickets",
      "ageRestriction",
      "cancellationPeriod",
      "categories",
      "desc_en",
      "desc_nb",
      "duration",
      "editableBy",
      "embeddedVideoURL",
      "endDate",
      "endTime",
      "eventCancelled",
      "eventSoldOut",
      "facebookURL",
      "images",
      "isFeatured",
      "maximumAge",
      "minimumAge",
      "moreInfoURL",
      "mode",
      "noTicketsInfo",
      "organizers",
      "paymentMethod",
      "prices",
      "priceOption",
      "publishingDate",
      "publishingOption",
      "publishingTime",
      "registrationEnabled",
      "repetitions",
      "startTime",
      "startDate",
      "status",
      "streamingURL",
      "super_event",
      "synchroDestinations",
      "tags",
      "ticketsURL",
      "ticketsFromDate",
      "ticketsFromTime",
      "title_nb",
      "title_en",
      "type",
      "updated_at",
      "venue",
      "venueNote",
      "videosURL"
    ];
    const newEvent = {};
    if (inEvent) {
      for (let k of Object.keys(inEvent)) {
        if (fields.indexOf(k) != -1) {
          newEvent[k] = inEvent[k];
        }
      }
      return newEvent;  
    }
    return {};
  }


}
