<div class="full-width">
  <div *ngIf="images?.length > 0">
    <div *ngFor="let image of images; let imageIndex = index;">
      <div *ngIf="imageIndex == currentCarrouselIndex && image.urlLarge">
        <div class="image-block full-width">
          <div class="text-center" [ngClass]="{ 'cover-image-container': environment.theme == 'trdevents'}" fxLayout="row" fxLayoutAlign="center center">
            <img class="cover-image" mat-card-image [src]="image.urlLarge" [alt]="image.alt || ''">
          </div>
          <button *ngIf="environment.theme == 'classic' && currentCarrouselIndex > 0" class="arrow-left" mat-icon-button aria-label="Previous image" (click)="previousPicture()">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button *ngIf="environment.theme == 'classic' && currentCarrouselIndex < (images.length - 1)" class="arrow-right" mat-icon-button aria-label="Next image" (click)="nextPicture()">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
          <div *ngIf="images.length > 1" class="image-shortcuts" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
            <div *ngFor="let image of images; let buttonIndex = index;">
              <mat-icon *ngIf="currentCarrouselIndex != buttonIndex" (click)="currentCarrouselIndex = buttonIndex">lens</mat-icon>
              <mat-icon *ngIf="currentCarrouselIndex == buttonIndex" (click)="currentCarrouselIndex = buttonIndex">adjust</mat-icon>
            </div>
          </div>
        </div>
        <div *ngIf="image.caption || image.credits" class="image-footer" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between baseline" fxLayout.xs="column" fxLayoutGap.xs="10px">
          <div *ngIf="image.caption" class="image-description">{{image.caption}}</div>
          <div *ngIf="image.credits" fxFlex="noshrink" class="image-credits">{{'Credits' | translate}}: {{image.credits}}</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="image && (!images || images.length == 0)">
    <img class="event-image" [src]="image" />
  </div>
</div>
