<div class="filters" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap.lt-md="10px" fxLayoutGap="30px">
        <h2 *ngIf="!loading && title">{{title}} {{totalHits && !filter.onlyFeatured ? '(' + totalHits + '):' : ''}}</h2>
        <h2 *ngIf="loading">{{'Loading' | translate}}...</h2>
        <a class="clear-filters" *ngIf="filter?.categories?.length > 0 || filter?.fromDate || filter?.untilDate" routerLink="/" fragment="top">{{'Clear filter' | translate}}</a>
    </div>
    <div class="top" >
        <form [formGroup]="dateRangeForm" class="inputs-container">
            <mat-date-range-input class="dateinputs" [rangePicker]="picker">
                <input matStartDate formControlName="fromDate" [placeholder]="'From date' | translate">
                <input matEndDate formControlName="untilDate" [placeholder]="'To date' | translate">
            </mat-date-range-input>
            <mat-date-range-picker xPosition="below" #picker>
                <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>{{'Cancel' | translate}}</button>
                    <button mat-raised-button color="primary" matDatepickerApply>{{'Apply' |
                        translate}}</button>
                </mat-datepicker-actions>
            </mat-date-range-picker>
        </form>
        <button fxLayout="row" fxLayoutAlign="space-between center"
            (click)="picker.open()"><mat-icon>today</mat-icon>{{'Date filter' | translate}}</button>
    </div>
</div>
<div class="events" fxLayout="column" fxLayoutAlign="center center">
    <div fxHide.lt-md class="subcategories" fxLayout="row wrap" *ngIf="subCategories?.length > 0 && !loading" fxLayoutAlign="center center" fxLayoutGap="30px" fxLayoutGap.lt-md="15px">
        <a routerLinkActive [ngClass]="{ 'active-link' : rla.isActive || selectedSubCategory?.id == category?.id }" #rla="routerLinkActive" *ngFor="let category of subCategories" [routerLink]="['/', 'category', category.slug]" fragment="top">{{category.name}}</a>        
    </div>
    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="50"></mat-spinner>
    </div>
    <div *ngIf="!loading" class="full-width" fxLayout="row wrap" fxLayoutAlign="space-between start" fxLayoutAlign.lt-md="start start">
        <app-event-tile fxFlex="32%" fxFlex.lt-md="100%" *ngFor="let event of eventList; let last = last" [event]="event"
            (afterViewInit)="last ? lastEventIsRendered() : null"></app-event-tile>
    </div>
    <div *ngIf="!loading && eventList && eventList.length > 0" class="margin-bottom margin-top">
        <button *ngIf="moreEventsAvailable() && !loadMoreIsLoading" (click)="loadMoreEvents()" fxLayout="row"
            fxLayoutAlign="center center">
            <mat-icon>cached</mat-icon> {{'Load more' | translate}}
        </button>
        <div *ngIf="loadMoreIsLoading">
            <mat-spinner diameter="50"></mat-spinner>
        </div>
    </div>
</div>